import React from 'react';
import { Table, Button } from 'react-bootstrap';

const VehicleTable = (props) => {
    const {vehicles} = props;

    return ( 
        <Table className='mt-3'>
            <thead>
                <tr>
                    <th>Location</th>
                    <th>No</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                {vehicles && vehicles.length > 0 ? (
                    vehicles.map((vehicle) => (
                        <tr key={vehicle.id}>
                            <td>{vehicle.location.name}</td>
                            <td>{vehicle.no}</td>
                            <td>{vehicle.make}</td>
                            <td>{vehicle.model}</td>
                            <td className="text-right">
                            <Button variant="outline-primary" onClick={() => props.editVehicle(vehicle)}>Edit</Button> { ' ' }
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                    <td colSpan={3}>No Vehicles</td>
                    </tr>
                )}
            </tbody>
        </Table>
     );
}
 
export default VehicleTable;