import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Form, Button } from 'react-bootstrap';
import { authAction } from '../../actions/authAction';

import "./Login.css";
import PageMessage from '../../utils/pageMessage';

export default function Login(props) {

    const dispatch = useDispatch();

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const location = useLocation();

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();

        const { from } = location.state || { from: { pathname: "/" } };
        dispatch(authAction.login(username,password, from));

    }

  return(
    <Container className="Login">
        <PageMessage />
        <Form onSubmit={handleSubmit}>
            <Form.Group size="lg" controlId="email">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    autoFocus
                    type="text"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
              
                />
            </Form.Group>
            <Form.Group size="lg" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
             
                />
            </Form.Group>
            <Button block size="lg" type="submit" disabled={!validateForm()}>
                Login
            </Button>
        </Form>
    </Container>
  )
}