import axios from 'axios';
import { alertConstant } from '../constants/alertConstant';

export const alertAction = {
    initializePage,

    success,
    error,
     
};

function initializePage() {
    return dispatch => {
        dispatch(success());
    };

    function success() { return { type: alertConstant.INIT_PAGE } }
}

function success(message) {
    return dispatch => {
        dispatch(success(message));
    };

    function success(message) { return { type: alertConstant.READ_SUCCESS, message: message.message } }
}

function error(message) {
    return dispatch => {
        dispatch(success(message));
    };

    function success(message) { return { type: alertConstant.READ_ERROR, message: message.message } }
}