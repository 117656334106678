import React, { useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import ResourcePage from '../components/resource';
import VehiclePage from '../components/vehicle';

const AdminPage = (props) => {
    
    const [showComponent, setShowComponent] = useState('');

    const [adminMenu, setAdminMenu] = useState([
        {id:1, path:'vehicle', label:'Vehicle', active: true},
        {id:2, path:'resource', label:'Resource'}
    ]);

    const onClick = (path) => {
        setShowComponent(path);
        setAdminMenu(adminMenu.map(menu => menu.path === path ? {...menu, active: true} : {...menu, active: false}));
    }

    return ( 
        <Container fluid className='mt-1'>
            <Row>
            <Col md="2" className='p-0'>
                <ListGroup className='m-0'>
                    {  adminMenu &&  adminMenu.map((menuItem, i) => 
                        <ListGroup.Item action key={menuItem.id} 
                            data-index={menuItem.id} 
                            onClick={() => onClick(menuItem.path)} 
                            active={menuItem.active}
                        >

                            {menuItem.label} 

                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Col>
            <Col md="10">
                { showComponent === 'vehicle' && <VehiclePage/> }
                { showComponent === 'resource' && <ResourcePage/> }
            </Col>
            </Row>
        </Container>
     );
}
 
export default AdminPage;