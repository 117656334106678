import axios from 'axios';
import { authConstant } from '../constants/authConstant';

import authHeader from "../utils/auth-header";
import {history} from "../utils/history";

import { REACT_APP_API_URL } from '../utils/applicationProperties';
import { alertAction } from './alertAction';

export const authAction = {
    login,
    isTokenValid,
    logout,
};

function login(username, password, from) {
    return dispatch => {
        axios.post( REACT_APP_API_URL  + '/api/login',{username,password})
        .then (function(response) {
            console.log(response);
            localStorage.setItem("sk-token", response.data.apiKey);
            dispatch(success(response.data));
            dispatch(alertAction.initializePage());
            history.push(from);
        })
        .catch(function (error) {
            console.log(error);
            dispatch(alertAction.error({message: "Invalid Username or Password"}));

        });
    }

    function success(user) { return { type: authConstant.LOGIN_SUCCESS, user } }
}

function isTokenValid() {
    return dispatch => {
        axios.get( REACT_APP_API_URL  + '/api/isTokenValid', { headers: authHeader() })
        .then (function(response) {
            console.log(response);
            dispatch(success(response.data));
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            //dispatch(alertAction.error(error));
        });
    }

    function success(user) { return { type: authConstant.LOGIN_SUCCESS, user } }
}

function logout() {
    history.push("/login");
    return { type: authConstant.LOGOUT_SUCCESS, action: {} }
}