import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ListGroup, Row, Col, CloseButton, InputGroup, Form}  from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {scheduleAction} from '../actions/scheduleAction';

const listGroupScroll = {
    maxHeight: 450, 
    marginBottom: 10, 
    marginTop: 10, 
    overflowY: 'scroll'
}

const NewScheduleItems = ({nextOnClick}) => {

    const tRequestList = useSelector(state => state.schedule.notscheduledTRequests);
    const selectedtRequestList = useSelector(state => state.schedule.selectedTRequests);
    const [error, setError] = useState("");

    const [filterPattern, setFilterPattern] = useState("");
    const [filteredPendingList, setFilteredPendingList] = useState([]);

    const activeLocation = useSelector(state => state.location.activeLocation);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(scheduleAction.fetchNotscheduledTRequests(activeLocation.id));
    }, []);

    useEffect(() => {
        setFilteredPendingList(tRequestList);
        filterPending(filterPattern);
    }, [tRequestList]);

    const addToList = (tRequest) => {
        dispatch(scheduleAction.addToListTRequest(tRequest));
    }

    const filterOnChange = (e) =>  {
        setFilterPattern(e.target.value);
        filterPending(e.target.value)
    }

    const filterPending = (pattern) => {
        tRequestList && setFilteredPendingList(tRequestList.filter(request => request.referenceNo1.toLowerCase().includes(pattern.toLowerCase())));
    }

    const onClickNext = () => {
        
        if (selectedtRequestList && selectedtRequestList.length  > 0) {
            nextOnClick();
        } else {
            setError("No Request attached to the schedule.");
        }
    }

    const removeFromList = (tRequest) => {
        dispatch(scheduleAction.removeFromListTRequest(tRequest));
    }

    return ( 
        <div>
        <Row>
            <Col sm={6}>
                <h5> Pending Requests </h5>

                <InputGroup>
                <Form.Control
                onChange={filterOnChange}
                                type="text"
                                placeholder="Search with PO Number.."
                            />

                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    {/*<FontAwesomeIcon icon="faEdit" />*/}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            
                        </InputGroup>

                <ListGroup style={listGroupScroll}>
                    {  filteredPendingList &&  filteredPendingList.map((tRequest, i) => 
                        <ListGroup.Item action key={tRequest.requestID} data-index={tRequest.requestID} onClick={() => addToList(tRequest)}>
                            <Row>
                                <Col>{tRequest.referenceNo1}</Col>
                                <Col>{tRequest.referenceNo2}</Col>
                                <Col style={{textAlign: "right"}}>{tRequest.amount}</Col>
                            </Row>
                            <Row>
                                <Col>{tRequest.customerName} {' - '} {tRequest.customerCity}</Col>
                            </Row>
                        </ListGroup.Item>
                        )}
                </ListGroup>
            </Col>
            <Col sm={6}>
                <h5>Selected Requests </h5>
                <ListGroup style={listGroupScroll}>
            {  selectedtRequestList &&  selectedtRequestList.map((tRequest, i) => 
                            <ListGroup.Item key={tRequest.requestID} data-index={tRequest.requestID} >
                                <Row>
                                    <Col md="3">{tRequest.referenceNo1}</Col>
                                    <Col md="4">{tRequest.referenceNo2}</Col>
                                    <Col style={{textAlign: "right"}} md="4">{tRequest.amount}</Col>
                                    <Col style={{textAlign: "right"}} md="1"><CloseButton onClick={() => removeFromList(tRequest)}/></Col>
                                </Row>
                                <Row>
                                    <Col>{tRequest.customerName} {' - '} {tRequest.customerCity}</Col>
                                </Row>
                        </ListGroup.Item>
                        )}
                </ListGroup>
            </Col>
        </Row>
        <Modal.Footer>
        { error &&
            <h3 className="error"> { error } </h3> }
            <Button variant="primary" onClick={onClickNext}> Next </Button>
        </Modal.Footer>
        </div>
    );
}

export default NewScheduleItems;