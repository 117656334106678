import { dashboardConstant } from '../constants/dashboardConstant.js';

const initialState = {  tRequests : [ ],
                        schedules : [ ],
                        dispatches: [ ],
                    };

export function dashboardReducer (state = initialState, action) {
    
    switch (action.type) {
        case dashboardConstant.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case dashboardConstant.GETALL_SUCCESS:
            return {
                ...state,
                tRequests: action.dsdata.tRequests,
                schedules: action.dsdata.schedules,
                dispatches: action.dsdata.dispatches,
                loading: false
            }
        default:
            return state
    }
}