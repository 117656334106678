import React, { useEffect, useState } from 'react';
import { ListGroup, Row, Col, Button, Form } from "react-bootstrap";
import authHeader from '../utils/auth-header';

const DispatchForm = ({currDispatch, saveDispatch}) => {

    const [dispatch, setDispatch] = useState(currDispatch);
    const[message, setMessage] = useState({});

    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

    useEffect(() => {
        setDispatch({...dispatch, authLevel: "50", comment: "Dispatched Schedule"});
        console.log(currDispatch);

    }, [])

    const handleInputChange = event => {
        const { name, value } = event.target
    
        setDispatch({ ...dispatch, isEditing: true, [name]: value });

        setIsSubmitButtonDisabled(false);
    }

    function validateForm () {
        let errors = {error: false};

        errors = (dispatch.contactNo.length != 10)     ? {...errors, error: true, contactNo: "Invalid Contact No"}    : errors ;
        errors = (dispatch.meterReading.length <= 0)     ? {...errors, error: true, meterReading: "Invalid Meter Reading"}    : errors ;
        errors = (parseFloat(dispatch.meterReading) <= 0)     ? {...errors, error: true, meterReading: "Invalid Meter Reading"}    : errors ;
        

        setMessage(errors);

        return errors.error;
    }

    const submitDispatch = (event) => {
        event.preventDefault();
        
        if(! validateForm()) {
            setIsSubmitButtonDisabled(true);
            setDispatch({...dispatch, isEditing:false});
            saveDispatch({...dispatch, isEditing:false});
        }
    }

    return ( 
        <>
            <Form>
                <Form.Group>
                    <Form.Label>Contact No</Form.Label>
                    <Form.Control 
                        value={dispatch.contactNo} 
                        name="contactNo" 
                        onChange={handleInputChange} 
                        isInvalid={message.contactNo}
                    />
                    <Form.Control.Feedback type="invalid">
                        {message.contactNo}
                    </Form.Control.Feedback>
                </Form.Group>


                <Form.Group>
                    <Form.Label>Meter Reading</Form.Label>
                    <Form.Control 
                        value={dispatch.meterReading} 
                        name="meterReading" 
                        onChange={handleInputChange} 
                        isInvalid={message.meterReading}
                    />
                    <Form.Control.Feedback type="invalid">
                        {message.meterReading}
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="outline-primary" onClick={submitDispatch} disabled={isSubmitButtonDisabled}> Dispatch </Button>
            </Form>
        </>
     );
}
 
export default DispatchForm;