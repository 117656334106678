import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import NewScheduleHeader from '../components/NewScheduleHeader';
import NewScheduleItems from '../components/NewScheduleItems';
import { scheduleAction } from '../actions/scheduleAction';
import PageMessage from '../utils/pageMessage';
import ScheduleHeader from '../components/ScheduleHeader';
import RequestList from '../components/RequestList';
import { Container } from 'react-bootstrap';
import ScheduleAdditionalInfo from '../components/ScheduleAdditionalInfo';

const NewScheduleModal = ({show, handleClose}) => {  

    const dispatch = useDispatch();

    const schedule = useSelector(state => state.schedule);
    const selectedTRequests = useSelector(state => state.schedule.selectedTRequests);
    
    const [currStage, setCurrStage] = useState(1);
    const [isCreateBtnDisabled, setIsCreateBtnDisabled] = useState(false);

    const scheduleHeader = useSelector(state => state.schedule.header);

    const activeLocation = useSelector(state => state.location.activeLocation);

    useEffect(() => {
        setCurrStage(1);
        setIsCreateBtnDisabled(false);
    }, []);

    useEffect(() => {
        setCurrStage(1);
        setIsCreateBtnDisabled(false);
        dispatch(scheduleAction.resetNewSchedule());
    }, [show]);

    const createSchedule = (e) => {
        setIsCreateBtnDisabled(true);
        dispatch(scheduleAction.createSchedule(schedule));
        dispatch(scheduleAction.getAll(activeLocation))
    }

    const nextOnClick = () => {
        if (currStage === 2) {
            setCurrStage(currStage + 2);  // Since No Additional Information captured Skiping for the time
        } else {
            setCurrStage(currStage + 1)
        }
    }

    return ( 
        <Modal show={show} onHide={handleClose} size='xl' backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>New Schedule</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <Container fluid>
                
                { ( currStage > 1) && <ScheduleHeader headerDetail={scheduleHeader}/> }
                <hr/>
                <PageMessage />
                { ( currStage === 1) &&<NewScheduleHeader activeLocation={activeLocation}/*handleHeader={handleHeader}*/ nextOnClick={nextOnClick}/> }
                { ( currStage === 2) &&<NewScheduleItems nextOnClick={nextOnClick}/> }
                { ( currStage === 3) &&<ScheduleAdditionalInfo nextOnClick={nextOnClick}/> }
                { ( currStage === 4) &&<RequestList isEditable={false} tRequests={selectedTRequests} />}

            </Container>
            </Modal.Body>

            <Modal.Footer>
                {
                    ( currStage === 4) && (
                        <Button variant="primary" onClick={createSchedule} disabled={isCreateBtnDisabled}>
                            Save Schedule
                        </Button>
                    )
                }
           
            </Modal.Footer>
        </Modal>
    );
}

export default NewScheduleModal;