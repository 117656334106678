import axios from 'axios';
import { locationConstant } from '../constants/locationConstant';
import { scheduleAction } from './scheduleAction';
import { alertAction } from './alertAction';

import authHeader from "../utils/auth-header";

import { REACT_APP_API_URL } from '../utils/applicationProperties';

export const locationAction = {
    getAllUserLocation,
    setActiveLocation,
};

function getAllUserLocation() {
    return dispatch => {
        dispatch(request());
        //dispatch(init());
        axios
            .get( REACT_APP_API_URL  + '/user/locations', { headers: authHeader() }) 
            .then(function (response) {
                dispatch(success(response.data.data.locations))
            })
            .catch(function (error) {
                console.log(error);
                dispatch(alertAction.error(error));
            });
    };

    function init() { return { type: locationConstant.INIT}}
    function request() { return { type: locationConstant.GETALL_REQUEST } }
    function success(locations) { return { type: locationConstant.GETALL_SUCCESS, locations } }
    function failure(error) { return { type: locationConstant.GETALL_FAILURE, error } }
}


function setActiveLocation(location) {
    return dispatch => {
        dispatch(request());
        dispatch(success(location));
        //dispatch(scheduleAction.getAll(location));
        //dispatch(scheduleAction.getReferenceData(location));
    }
    
    function request() { return { type: locationConstant.GETALL_REQUEST } }
    function success(location) { return { type: locationConstant.SET_ACTIVE_LOCATION_SUCCESS, location } }

}