import React, { useEffect, useState } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";

const ResourceFormOth = (props) => {
    const[resource, setResource] = useState(props.resource);
    const[resources] = useState(props.resources);
    const[readOnly, setReadOnly] = useState(true);

    const[message, setMessage] = useState({});

    useEffect(() => {
        resource.id == 1 ? setReadOnly(true): setReadOnly(false);
        setResource(props.resource);
    },[props.resource])

    useEffect(() => {
        validateForm();
    },[resource])

    const handleInputChange = event => {
        const { name, value } = event.target
        setResource({ ...resource, isEditing: true, [name]: value });
        props.updateResource({ ...resource, isEditing: true, [name]: value });
    }

    const handleResourceInputChange = event => {
        const { name, value } = event.target;
        if(value != "") {
            let newResource = resources.filter(resource => resource.id === value)[0];
            setResource({...newResource, isEditing: true});
        }
    }

    const onChecked = (event) => {

        setReadOnly(event.target.checked);
        props.updateResource({...resource, isEditing:true});
        if (event.target.checked) {
            setResource({ ...resource, isEditing: true, id: 1, name: "", nic: "" });
            
        } else {
            setResource({ ...resource, id: 0, name: "", nic: "" });
        }
    }

    function validateForm () {
        let errors = {error: false};

        errors = (resource.id < 1 )             ? {...errors, error: true, name: "Invalid Resource"}    : errors ;
        errors = (resource.name.length < 5)     ? {...errors, error: true, name: "Invalid Name"}    : errors ;
        errors = (resource.nic.length < 10)     ? {...errors, error: true, nic: "Invalid NIC Number"}  : errors ;

        setMessage(errors);
        return errors.error;
    }

    const updateResource = (event) => {
        event.preventDefault();

        if(!validateForm()) {
            setResource({...resource, isEditing:false});
            props.updateResource({...resource, isEditing:false});
        }
    }

    return ( 
        <Card>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="resourceName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control value={resource.name} 
                                name="name" 
                                onChange={handleInputChange} 
                                readOnly={!readOnly} 
                                isInvalid={message.name} />
                            <Form.Control.Feedback type="invalid">
                                {message.name}
                            </Form.Control.Feedback> 
                        </Form.Group>
                        <Form.Group as={Col} controlId="resourceNIC">
                            <Form.Label>NIC</Form.Label>
                            <Form.Control value={resource.nic} 
                                name="nic" 
                                onChange={handleInputChange} 
                                readOnly={!readOnly} 
                                isInvalid={message.nic} />
                            <Form.Control.Feedback type="invalid">
                                {message.nic}
                            </Form.Control.Feedback> 
                        </Form.Group>
                    </Form.Row>

                    <Form.Group id="resourceThirdParty">
                        <Form.Check type="checkbox" label="Third Party"  checked={readOnly} name="thirdParty" 
                            onChange={onChecked} />
                    </Form.Group>

                    <Form.Group controlId="registeredResource">
                        <Form.Control as="select" custom name="resource" 
                                        onChange={handleResourceInputChange} 
                                        value={resource.id}
                                        disabled={readOnly} >
                            <option value="">Select a resource</option>
                        {  resources &&  resources.map((rResource, i) => 
                            <option key={rResource.id} value={rResource.id} > {rResource.name} </option>
                        )}
                        </Form.Control>
                    </Form.Group>

                    {resource.isEditing && <Button onClick={updateResource} >Save Change</Button> }
                </Form>
            </Card.Body>
        </Card>
     );
}
 
export default ResourceFormOth;