import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Modal, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { scheduleAction } from '../actions/scheduleAction';

const ScheduleAdditionalInfo = (props) => {

    const dispatch = useDispatch();
    //const additionalInfos = useSelector(state => state.schedule.additionalInfos);
    const [additionalInfos, setAdditionalInfos] = useState({isFull: false});
    const [errorState, setErrorState] = useState({});

    const loadedGrossWeight = useSelector(state => state.schedule.header.grossWeight);

    useEffect(() => {
        console.log(JSON.stringify(additionalInfos));
    },[additionalInfos]);

    const onChange = (event) => {
        const {name, value} = event.target;
        setAdditionalInfos({...additionalInfos, [name]: value});
    }

    const onChecked = (event) => {
        const {name} = event.target;
        setAdditionalInfos({...additionalInfos, [name]: !additionalInfos.isFull});
        console.log(!additionalInfos.isFull);
    }

    const onClickNext = () => {
        setErrorState({error: false})
        let errors = {error: false}
        
        if(!additionalInfos.filledCapacity || additionalInfos.filledCapacity <=0) {
            errors = {...errors, error: true, vehicle : "Invalid Filled Capacity"};
        }

        setErrorState(errors)

        if (!errors.error) {
            console.log("Calling nextOnClick");
            dispatch(scheduleAction.updateStateAdditionalInfo(additionalInfos));
            props.nextOnClick();
        }

        console.log("Exiting HeaderOnclick");
    }

    
    return ( 
        <Card>
            <Card.Body>
                <Card.Title>Additional Information </Card.Title>


                <Form.Group as={Row} id="isFull">
                    <Form.Label column sm={4}>Is Vehicle Full</Form.Label>
                    <Col sm={8}>
                        <Form.Check type="checkbox"  name="isFull" onChange={onChecked} value={additionalInfos.isFull}  />
                    </Col>
                </Form.Group> 

                <Form.Group as={Row} controlId="filledCapacity">
                    <Form.Label column sm={4}>Filled Capacity </Form.Label>
                    <Col sm={4}>
                        <Form.Control type="number" label="Filled Capacity" name="filledCapacity" onChange={onChange}/>
                    </Col>
                    <Form.Label column sm={4}>System Estimated: {loadedGrossWeight} kg</Form.Label>
                </Form.Group>        
            </Card.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={onClickNext}> Next </Button>
            </Modal.Footer>
        </Card>
     );
}
 
export default ScheduleAdditionalInfo;