import { combineReducers } from 'redux';

import { scheduleReducer } from './scheduleReducer';
import { customerReducer } from './customerReducer';
import { alertReducer } from './alertReducer';
import { locationReducer } from './locationReducer';
import { resourceReducer } from './resourceReducer';
import { authReducer } from './authReducer';
import { dashboardReducer } from './dashboardReducer';
import { returnReducer } from './returnReducer';

const rootReducer = combineReducers({
    alert: alertReducer,  
    auth: authReducer,
    customer: customerReducer,
    dashboard: dashboardReducer,
    location: locationReducer,
    resource: resourceReducer,
    return: returnReducer,
    schedule: scheduleReducer,
  });
  
  export default rootReducer;