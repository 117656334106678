import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import {scheduleAction} from '../actions/scheduleAction';

const NewScheduleHeader = ({activeLocation, nextOnClick}) => {

    const initialFormState = { driverId: '', helperId: '', vehicleId: '', scheduleDate: new Date(), locationId: activeLocation.id };
    
    const [ scheduleHeader, setScheduleHeader ] = useState(initialFormState);
    const [ errorState, setErrorState] = useState({error: false});

    const dispatch = useDispatch();

    const vehicleList = useSelector(state => state.schedule.vehicles);
    const driverList = useSelector(state => state.schedule.drivers);
    const helperList = useSelector(state => state.schedule.helpers);

    const [date, setDate] = useState(new Date());
    const handleChange = date => {
        setDate(date);
        setScheduleHeader({ ...scheduleHeader, scheduleDate: date });
    } 

    useEffect(() => {
        setScheduleHeader(initialFormState);
        dispatch(scheduleAction.getReferenceData(activeLocation));
    }, []);

    const handleVehicleInputChange = event => {
        const { name, value } = event.target;
        setScheduleHeader({ ...scheduleHeader, [name]: vehicleList.filter(vehicle => vehicle.id === value)[0] });
        
    }

    const handleDriverInputChange = event => {
        const { name, value } = event.target;
        setScheduleHeader({ ...scheduleHeader, [name]: driverList.filter(driver => driver.id === value)[0] });     
    }

    const handleHelperInputChange = event => {
        const { name, value } = event.target;
        setScheduleHeader({ ...scheduleHeader, [name]: helperList.filter(helper => helper.id === value)[0] });
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        setScheduleHeader({...scheduleHeader, [name]: value});
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        console.log(value)
        setScheduleHeader({ ...scheduleHeader, [name]: value });   
    }

    const onClickNext = () => {
        console.log(JSON.stringify(scheduleHeader))
        setErrorState({error: false})
        let errors = {error: false}
        
        if(!scheduleHeader.vehicle || scheduleHeader.vehicle <=0) {
            errors = {...errors, error: true, vehicle : "Invalid Vehicle"};
        }

        if(!scheduleHeader.driver || scheduleHeader.driver <=0) {
            errors = {...errors, error: true, driver : "Invalid Driver"};
        }

        if(!scheduleHeader.helper || scheduleHeader.helper <=0) {
            errors = {...errors, error: true, helper : "Invalid Helper"};
        }

        if(!scheduleHeader.route || scheduleHeader.route === "") {
            errors = {...errors, error: true, route : "Invalid Route"};
        }

        //const oldDate = moment('2010-10-20').isSameOrAfter('2010-10-20', 'day');
        if(!scheduleHeader.scheduleDate || scheduleHeader.scheduleDate <=0) {
            errors = {...errors, error: true, scheduleDate : "Invalid Schedule Date"};
        } else if (moment(scheduleHeader.scheduleDate, 'DD-MMM-YYYY').isBefore(new Date('DD-MMM-YYYY'))) {
            errors = {...errors, error: true, scheduleDate : "Schedule date is less than current date"};
        }

        setErrorState(errors)

        if (!errors.error) {
            console.log("Calling nextOnClick");
            dispatch(scheduleAction.setStateHeader(scheduleHeader)) ;
            nextOnClick();
        }

        console.log("Exiting HeaderOnclick");
    }

    return ( 
        <>
            <Form>
                
                <Form.Group as={Row} controlId="scheduleForm.scheduleDate">
                    <Form.Label column sm={4} >Schedule Date</Form.Label>
                    <Col sm={4}>
                    <DatePicker
                    selected={date} onChange={handleChange} dateFormat="dd-MM-yyyy" isInvalid={errorState.driverId}/> 
                    <Form.Control.Feedback type="invalid">
                        {errorState.scheduleDate}
                    </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="scheduleForm.route">
                    <Form.Label column sm={4}> Route </Form.Label>
                    <Col sm={4}>
                        <Form.Control type="text" label="Route Name" name="route" onChange={onChange} isInvalid={errorState.route} />
                        <Form.Control.Feedback type="invalid">
                            {errorState.route}
                    </Form.Control.Feedback>
                    </Col>
                    
                </Form.Group>  

                <Form.Group controlId="scheduleForm.vehicle">
                    <Form.Label>Vehicle</Form.Label>
                    <Form.Control as="select" custom name="vehicle" onChange={handleVehicleInputChange}
                    isInvalid={errorState.vehicle}>
                        <option value="">Select a vehicle</option>
                    {  vehicleList &&  vehicleList.map((vehicle, i) => 
                        <option key={vehicle.id} value={vehicle.id}> {vehicle.no} {vehicle.make} {vehicle.model}</option>
                    )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {errorState.vehicle}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="scheduleForm.driver">
                    <Form.Label>Driver</Form.Label>
                    <Form.Control as="select" custom name="driver" onChange={handleDriverInputChange}
                    isInvalid={errorState.driver}>
                        <option value="">Select a Driver</option>
                    {  driverList &&  driverList.map((driver, i) => 
                        <option key={driver.id} value={driver.id}> {driver.name}</option>
                    )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {errorState.driver}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="scheduleForm.helper">
                    <Form.Label>Helper</Form.Label>
                    <Form.Control as="select" custom name="helper" onChange={handleHelperInputChange}
                    isInvalid={errorState.helper}>
                        <option value="">Select a Helper</option>
                    {  helperList &&  helperList.map((helper, i) => 
                        <option key={helper.id} value={helper.id}> {helper.name} </option>
                    )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {errorState.helper}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>

            <Modal.Footer>
                <Button variant="primary" onClick={onClickNext}> Next </Button>
            </Modal.Footer>
        </>
    );
}

export default NewScheduleHeader;