import React, { useEffect, useState  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Form, Col, Button, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import ResourceFormOth from './resource/ResourceFormOth';
import VehicleFormOth from './vehicle/VehicleFormOth';
import RequestList from './RequestList';
import EditModal from '../modals/EditModal';
import { scheduleAction } from '../actions/scheduleAction';
import moment from 'moment';

const ScheduleApproval = (props) => {

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);

    const [showEditModel, setShowEditModel] = useState(false);
    const handleClose = () => setShowEditModel(false);

    const vehicles = useSelector(state => state.schedule.vehicles);
    const drivers = useSelector(state => state.schedule.drivers);
    const helpers = useSelector(state => state.schedule.helpers);
    const selectedTRequests = useSelector(state => state.schedule.selectedTRequests);
    const header = useSelector(state => state.schedule.header);

    const [scheduleDate, setScheduleDate] = useState(new Date());
    const [ vehicle, setVehicle ] = useState({...props.vehicle, isEditing: false});
    const [ driver, setDriver ] = useState({...props.driver, isEditing: false});
    const [ helper, setHelper ] = useState({...props.helper, isEditing: false});
    const [ tRequests, setTRequests] = useState({tRequests: props.tRequests, isEditing: false});

    const [ errors, setErrors] = useState({error:false});

    useEffect(() => {
        setScheduleDate(moment(props.scheduleDate).toDate())
    },[props.scheduleDate]);
  
    useEffect(() => {   
        setVehicle({...props.vehicle, isEditing: false});
        setDriver({...props.driver, isEditing: false});
        setHelper({...props.helper, isEditing: false});
        setTRequests({tRequests: props.tRequests, isEditing: false})
    }, [props.vehicle, props.driver, props.helper, props.tRequests])

    const [ enableSave, setEnableSave] = useState(false);

    useEffect(() => {
        (vehicle.isEditing || driver.isEditing || helper.isEditing) ? setEnableSave(false) : setEnableSave(true);
    },[vehicle.isEditing, driver.isEditing, helper.isEditing]);

    const updateVehicle = (vehicle) => {
        setVehicle(vehicle);
    }

    const updateDriver = (resource) => {
        setHelper(resource)
    }

    const updateHelper = (resource) => {
        setHelper(resource)
    }

    const saveApproval = () => {

        let lerrors = {error: false}

        if(!moment(scheduleDate).isValid()) {
            lerrors = {...errors, error: true, scheduleDate : "Invalid Schedule Date"};
        } else if (moment(scheduleDate, 'DD-MMM-YYYY').isBefore(moment().format('YYYY-MM-DD'))) {
            lerrors = {...errors, error: true, scheduleDate : "Schedule date should be greater than or equal to current date"};
        }

        setErrors(lerrors);
        if (!lerrors.error) {
            setEnableSave(false);
            props.saveApproval(header,moment(scheduleDate).format('YYYY-MM-DD'),vehicle,driver,helper,tRequests.tRequests);  
        }
        
    }

    const handleShowEdit = () => {
        dispatch(scheduleAction.setSelectedTRequests(tRequests.tRequests));
        setShowEditModel(true);
    }

    const tRequestOK = (tRequests) => {
        setTRequests({tRequests: (selectedTRequests.length > 0) ? selectedTRequests : tRequests.tRequests, isEditing: false})
        setShowEditModel(false);
    }

    const handleChange = date => {
        setScheduleDate(date);
    } 

    return ( 
        <>
            <Card>
                <Card.Body>
                    <Form.Group as={Row} controlId="scheduleEditForm.scheduleDate">
                        <Form.Label column sm={4} >Schedule Date</Form.Label>
                        <Col sm={4}>
                        <DatePicker 
                            minDate={new Date()}
                            selected={scheduleDate} onChange={handleChange} dateFormat="dd-MM-yyyy" /> 
                        </Col>
                    </Form.Group>
                </Card.Body>
            </Card>

            <VehicleFormOth title="Vehicle" vehicle={vehicle} updateVehicle={updateVehicle} isEditing={vehicle.isEditing} vehicles={vehicles}/> 

            <br/>

            <ResourceFormOth title="Driver" resource={driver} updateResource={setDriver} isEditing={driver.isEditing} resources={drivers}/>

            <br/>

            <ResourceFormOth title="Helper" resource={helper} updateResource={updateHelper} isEditing={helper.isEditing} resources={helpers}/>

            <br/>

            <Card>

                <Card.Body>
                    <Card.Title>Selected Orders <Button onClick={handleShowEdit}>Edit</Button></Card.Title>
                    
                    <RequestList tRequests={tRequests.tRequests}  />
                </Card.Body>
            </Card>

            <br/>

            {enableSave && <Button onClick={saveApproval}>Save Changes</Button>}

            <EditModal show={showEditModel} handleClose={handleClose} handleOK={tRequestOK}  />
        </>
     );
}
 
export default ScheduleApproval;