import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ListGroup, Container, Row, Col, Button }  from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { scheduleAction } from '../actions/scheduleAction';
import { customerAction } from '../actions/customerAction';

const OtherReturnItems = ({currSchedule, loadItems, cancelSchedule,deleveredSchedule}) => {

    const itemsList = useSelector(state => state.schedule.possibleReturnItems);
    const returnOthers = useSelector(state => state.schedule.returnOthers);
    const customerList = useSelector(state => state.customer.customerList);

    const dispatch = useDispatch();

    const [customer, setCustomer] = useState({});
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(customerAction.getAll());
    }, [])

    useEffect(() => {
        console.log(searchValue);
      }, [searchValue])   

    const onSelected = (event) => {
        console.log("onSelected" + JSON.stringify(event))
        setCustomer(event[0]);
        if (event.length > 0) {
            dispatch(scheduleAction.getPossibleReturnItems(event[0].id));
        }
    }

    const addReturn = (item) => {
        dispatch(scheduleAction.addReturnItem(customer,item));
    }

    const removeReturn = (item) => {
        dispatch(scheduleAction.removeReturnItem(customer,item));
    }

    const updateReturn = (item, event) => {
        console.log(event.target.value);
        dispatch(scheduleAction.updateReturnItem(customer, {...item, returnQuantity: event.target.value}));
    }

    return ( 
        <Container>
            <Row>
                <Typeahead
                    labelKey="name"
                    id="basic-example"
                    onSelected={onSelected}
                    onChange={onSelected}
                    options={customerList}
                    placeholder="Choose a Customer..."
                    value={searchValue}
                />
            </Row>

            <Row>
                <Col xs={4}>
                    <ListGroup>
                        {  itemsList &&  itemsList.map((item, i) => 
                            <ListGroup.Item action key={item.id} data-index={item.code} onClick={() => addReturn(item)}>
                                {item.description} - {item.code}
                            </ListGroup.Item>
                        )}
                    </ListGroup>

                </Col>
                <Col>
                    <ListGroup >
                        {  returnOthers &&  returnOthers.map((row, i) => 
                            <ListGroup.Item action key={row.customer.id} data-index={row.customer.id} >
                                {row.customer.name} - {row.customer.city}

                                {row.items.map((item,i) =>
                                    <ListGroup.Item key={i} >
                                        <Row>
                                            <Col xs={9}>{item.description}</Col>
                                            <Col xs={2}><input type="number" class="form-control" onChange={(e) => updateReturn(item,e)} /></Col>
                                            <Col xs={1}><Button  variant="danger" size="sm" onClick={() => removeReturn(item)}>X</Button></Col>
                                        </Row>
                                    </ListGroup.Item>
                                )}

                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
}

export default OtherReturnItems;