import React, { useEffect, useState } from "react";
import { Card, Container, Row,Col, ListGroup, ListGroupItem, CardGroup, Form, FormControl, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAction } from "../actions/dashboardAction";
import TopCard from "../components/ui/TopCard";

function DashboardPage(props) {

  const activeLocation = useSelector(state => state.location.activeLocation);

  const tRequests = useSelector(state => state.dashboard.tRequests);
  const schedules = useSelector(state => state.dashboard.schedules);
  const dispatches = useSelector(state => state.dashboard.dispatches);

  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(dashboardAction.getAll(activeLocation));
  },[]);

  const handleRoute = route => () => {
    this.props.history.push({ pathname: route });
  };

  const handleSearchSubmit = () => {
    if (searchText) {
      props.history.push({
        pathname: "/search",
        state: {
          searchText: searchText
        }
      });
    } else {
      alert("Please enter some search text!");
    }
  };
    
  return (
    <Container fluid className='pt-2'>

      <Row>
        <Col md="10">
          <Row>

            <TopCard
                title={tRequests.title}
                aTitle={tRequests.aTitle} aTotal={tRequests.aTotal}
                bTitle={tRequests.bTitle} bTotal={tRequests.bTotal}
              />

            <TopCard
                title={schedules.title}
                aTitle={schedules.aTitle} aTotal={schedules.aTotal}
                bTitle={schedules.bTitle} bTotal={schedules.bTotal}
              />
      
    
            <TopCard
                title={dispatches.title}
                aTitle={dispatches.aTitle} aTotal={dispatches.aTotal}
                bTitle={dispatches.bTitle} bTotal={dispatches.bTotal}
              />

            {/*<TopCard
              title={"Returns"}
              aTitle={''} aTotal={47}
              tagColor={"cyan"}
              prefix={"Today"}
              tagContent={"20"}
            />*/}
    
          </Row>
        </Col>
        <Col md="2">

        {/*<Form inline>
            <FormControl
              onChange={e => setSearchText(e.target.value)}
              value={searchText}
              type="text"
              placeholder="Search"
              className="mr-md-2"
            />
            <Button onClick={handleSearchSubmit} variant="outline-info">
              Search
            </Button>
          </Form>
        */}
        </Col>
      </Row>


    </Container>
  );
}

export default DashboardPage;