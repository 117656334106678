import React from 'react';
import { Container, Modal } from 'react-bootstrap';
import ScheduleTRequestForm from '../components/ScheduleTRequestForm';

const EditModal = ({show, handleClose, handleOK}) => { 
    return (
        <Modal show={show} onHide={handleClose} size='xl' backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Edit Orders</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <Container fluid>
                <ScheduleTRequestForm nextOnClick={handleOK}/>
            </Container>
            </Modal.Body>
        </Modal>
    )
}

export default EditModal;