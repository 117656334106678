import React from 'react';

import { Modal, Table } from 'react-bootstrap';

const TRequestModal = (props) => {
    const { show, lines } = props;
    const { handleClose, handleSuccess } = props;

    return ( 
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton >Request Lines</Modal.Header>

            <Modal.Body>
                
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Code</th>
                            <th>Item</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        { lines  ?  
                            (lines.map((line, i) =>
                                <tr key={i}>
                                    <td>{(i + 1)}</td>
                                    <td>{line.code}</td>
                                    <td>{line.description}</td>
                                    <td className="text-right">{line.totalQuantity}</td>
                                </tr>
                            ) 
                        ) :  (
                            <tr>
                                <td>No Data</td>
                            </tr>
                            
                        )}
                    </tbody>
                </Table>
            </Modal.Body>

        </Modal>
     );
}

export default TRequestModal;