import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const ScheduleDocuments = ({currSchedule}) => {

    const sessionUser = useSelector(state => state.auth.user.name);

    const print = (schedule) => {
        window.open('/pdfdocengin_live/pickListPDF.php?scheduleID='+schedule.id+'&user='+sessionUser,'printDeliveryNote','height=950,width=850,top=100,left=200,scrollbars=yes,resizable');
    }

    return (
        <div>
        <Row className="mb-1">
            <FontAwesomeIcon icon={faPrint} size="3x"  onClick={() => {print(currSchedule)}} pull="left" / > Pick List
        </Row>
        <Row className="mb-1">
            <FontAwesomeIcon icon={faPrint} size="3x"   pull="left" / > Delivery Note 
        </Row>
   
        
        </div>
    )
}

export default ScheduleDocuments;