import React, { useEffect, useState } from 'react';

import ReactPDF from '@react-pdf/renderer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faPrint } from '@fortawesome/free-solid-svg-icons';

import { Container, Button, Card, Col, ListGroup, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TRequestModal from '../modals/TRequestModal';
import PendingDispatchPDF from '../reports/PendingDispatchPDF';
import { scheduleAction } from '../actions/scheduleAction';

const PlanPage = () => {
    const tRequests = useSelector((state) => state.schedule.notscheduledTRequests);
    const activeLocation = useSelector(state => state.location.activeLocation);
    const sessionUser = useSelector(state => state.auth.user.name);

    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [currTRequest, setCurrTRequest] = useState();

    useEffect(()=> {
        dispatch(scheduleAction.getPendingRequests(activeLocation.id));
    },[]);

    const handleClose = () => setShow(false);

    const showLine = (tRequest) => {
        setCurrTRequest(tRequest);
        setShow(true);
    }

    const printPendingDispatch = () => {
        ReactPDF.renderToStream(<PendingDispatchPDF />);
    }

    const print = () => {
        window.open('/pdfdocengin_live/pendingDispatchPDF.php?lid='+activeLocation.id+'&user='+sessionUser,'printDeliveryNote','height=950,width=850,top=100,left=200,scrollbars=yes,resizable');
    }

    const refresh = () => {
        dispatch(scheduleAction.getPendingRequests(activeLocation.id));
    }

    return (
        <Container fluid className='pt-2'>
            <Card>
                <Card.Body>
                    <Card.Title>Pending Dispatch Report
                
                        <FontAwesomeIcon icon={faPrint} size="2x" border  pull="right" onClick={print} />
                        <FontAwesomeIcon icon={faSyncAlt} size="2x" border  pull="right" onClick={refresh} />
        
                    </Card.Title>

                    <Table>
                        <thead>
                            <tr>
                                <th> Sales Area </th>
                                <th> Schedule Date</th>
                                <th> Vehicle</th>
                                <th> Approval </th>
                                <th> Order No</th>
                                <th> Order Date</th>
                                <th> Aged </th>
                                <th> Customer</th>
                                <th> Agent </th>
                                <th> Invoice</th>
                                <th> Pen.Cr.App</th>
                                <th> </th>
                            </tr>
                        </thead>

                        <tbody>
                        { tRequests  ?  
                            (tRequests.map((tRequest, i) =>
                                <tr key={i}>
                                    <td> {tRequest.salesArea}</td>
                                    <td> {tRequest.schedule.scheduledDate}</td> 
                                    <td> {tRequest.schedule.vehicle.no}</td>
                                    <td> {(tRequest.schedule.authorizationStatus === '10' ? 'Y' : 'N') }</td>
                                    <td> {tRequest.referenceNo1}</td>
                                    <td> {tRequest.plannedDate}</td>
                                    <td className="text-right"> {tRequest.aged}</td>
                                    <td> {tRequest.customerName} {tRequest.customerCity}</td>
                                    <td> {tRequest.sfName}</td>
                                    <td> {tRequest.referenceNo2}</td>
                                    <td className="text-center"> {tRequest.pendingCreditApproval}</td>
                                    <td><Button onClick={() => showLine(tRequest)} >Lines</Button></td>
                                </tr>
                                )
                            )  : ( <tr><td colSpan="9">No Pending Dispatch</td></tr> )
                        }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {show && <TRequestModal show={show} lines={currTRequest.lines.items} handleClose={handleClose}/> }
        </Container>
    )
}

export default PlanPage;