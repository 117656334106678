import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ListGroup, Button, Row, Col, Modal, Card, CardGroup }  from 'react-bootstrap';

import { scheduleAction } from '../actions/scheduleAction';
import ReturnForm from './ReturnForm';
import TileButton from './ui/TileButton';
import NotDeliveredForm from './NotDeliveredForm';
import DeliveredForm from './DeliveredForm';
import { resourceAction } from '../actions/resourceAction';

const ItemList = (props) => {

    const [isNotDelivered, setIsNotDelivered] = useState(false);
    const [isDelivered, setIsDelivered] = useState(false);
    const [ errorState, setErrorState] = useState({error: false});
    
    const [currItem, setCurrItem] = useState();
    const [ returning, setReturning ] = useState(false);
    const [ allowReturning, setAllowReturning ] = useState(false);

    const [currScheduleItems, setCurrScheduleItems] = useState(useSelector(state => state.schedule.currScheduleItems));
    const items = useSelector(state => state.schedule.currScheduleItems);
    const [currDelivery, setCurrDelivery] = useState({isDelivered: "pending", isModified: false});
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("Item Stage Changed");
        setCurrScheduleItems(items);
    }, [items]);

    useEffect(() => {
        //setCurrDelivery(...props.currTRequest.delivery);
        console.log(JSON.stringify(props.currTRequest.delivery))
        setCurrDelivery({...props.currTRequest.delivery, isDelivered: (props.currTRequest.isDelivered ? currDelivery.isDelivered : "pending")});

    },[props.currTRequest.delivery])


    useEffect(() => {
        console.log(currDelivery);
    }, [currDelivery])

    const onReturnClick = (item) => {
        setReturning(true);
        setCurrItem(item);
        console.log(item.tRequestLineID);
    }

    const onSave = (e) => {
        console.log(props.currInvoice)
        dispatch(scheduleAction.updateDelivered(props.currTRequest.requestID,isNotDelivered));
        props.setIsOpen(false);
    }

    const updateItem = (updatedItem) => {
        setReturning(false);
        dispatch(scheduleAction.updateReturnQuantity(updatedItem));
    }

    const deliveredOnClick = () => {
        dispatch(resourceAction.getAllAgents());
        setCurrDelivery({...currDelivery, isDelivered: true, isModified: false});
        setIsDelivered(true);
        setIsNotDelivered(false);
    }

    const notDeliveredOnClick = () => {
        setCurrDelivery({...currDelivery, isDelivered: false, isModified: false})
        setIsDelivered(false);
        setAllowReturning(false);
        setIsNotDelivered(true);
    }

    const updateNotDelivery = (delivery) => {
        setIsNotDelivered(false);
        setAllowReturning(false);
        setCurrDelivery({...delivery, isModified: true});
        dispatch(scheduleAction.setNotDelivered(props.currTRequest.requestID, {...delivery, isDelivered: false}));
        dispatch(scheduleAction.updateReturnAllItem(delivery));
    }

    const updateDelivery = (delivery) => {
        setIsDelivered(false);
        setAllowReturning(true);
        setCurrDelivery({...delivery, isModified: true});
        dispatch(scheduleAction.setDelivered(props.currTRequest.requestID, {...delivery, isDelivered: true}));
    }

    return (   
        <Modal show={props.isOpen} size="xl" onHide={() => {props.setIsOpen(false)}}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.currTRequest.referenceNo2}
                    
                    <p className="card-text">
                        <small className="text-muted">{props.currTRequest.customerName} - {props.currTRequest.customerCity}</small>
                    </p>
                                    
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className="p-1">

                <Row>
                    <Col md="8">

                        <CardGroup>
                            <TileButton label="Delivered" selected={currDelivery.isDelivered}  onClick={deliveredOnClick} />
                            <TileButton label="Not Delivered" selected={!currDelivery.isDelivered} onClick={notDeliveredOnClick} />
                        </CardGroup>

                        <Card className="mt-2">
                            <Card.Body>
                        <ListGroup variant="flush" >  
                        { currScheduleItems  ?  
                            (currScheduleItems.map((item, i) => 
                                <ListGroup.Item key={i} >
                                    <Row >
                                        <Col> {item.description} </Col>
                                        <Col className="text-right"> {item.totalQuantity} </Col>
                                        <Col className="text-right"> 
                                        <Button variant="outline-primary" 
                                            onClick={() => onReturnClick(item)} 
                                            disabled={!allowReturning}
                                        > Mark Return </Button>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col md="2"><em>Returned : {item.returnQuantity}</em></Col>
                                        <Col md="2"><em>M.GRN No : {item.manualGRNNo}</em></Col>
                                        <Col md="3"><em>{item.returnReasonDesc}</em></Col>
                                        <Col md="5"><em>{item.returnRemark}</em></Col>
                                    </Row>
                                </ListGroup.Item>
                                ) 
                            )  : (
                                <p> No Items </p>
                            )
                        }
                        </ListGroup>
                    </Card.Body>
                    </Card>
                    </Col>

                    <Col md="4">
                        {isDelivered && <DeliveredForm currDelivery={currDelivery} updateDelivery={updateDelivery} updateIsDelivered={setIsDelivered} />}
                        {isNotDelivered && <NotDeliveredForm currDelivery={currDelivery} updateDelivery={updateNotDelivery} updateIsNotDelivered={setIsNotDelivered} />}
                        {returning && <ReturnForm currItem={currItem} setReturning={setReturning} updateItem={updateItem} /> }
                        
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>               
                <Button disabled={(!currDelivery.isModified) || isDelivered || isNotDelivered || returning } onClick={onSave} >OK</Button>
                <Button onClick={() => props.setIsOpen(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
     );
}
 
export default ItemList;