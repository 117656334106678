import React, { useEffect, useState } from 'react';
import IdentityTable from './IdentityTable';
import ResourceForm from './ResourceForm';


const ResourceDetail = ({currResource}) => {

    const[resource, setResource] = useState(currResource);
    const[readOnly, setReadOnly] = useState(true);

    useEffect(() => {
        console.log("props.resource --- ")
        console.log(JSON.stringify(currResource));
        
        if ( (currResource.id === "1") || (currResource.status === "I")) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        } 
    },[currResource]);

    return (
        <>

            <ResourceForm
                resource={resource} 
            /> 

            <IdentityTable />
        </>

    );
}

export default ResourceDetail;