import { customerConstant } from '../constants/customerConstant';

export function customerReducer(state = {customerList:[]}, action) {
    switch (action.type) {
        case customerConstant.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case customerConstant.GETALL_SUCCESS:
            return {
                ...state,
                customerList: action.customers,
                loading: false
            }
        default:
            return state
    }
}