import React from 'react';

import { Col, Row }  from 'react-bootstrap';
 
function ScheduleHeader({headerDetail}) {

  return (
    <Row >
        <Col md="6">
          <Row>
            <Col sm="4"> Reference No </Col>
            <Col sm="8"> {headerDetail.referenceNo} </Col>
          </Row>

          <Row>
            <Col md="4"> Scheduled </Col>
            <Col md="8"> {headerDetail.scheduledDate} </Col>
          </Row>

          <Row>
            <Col md="4"> Status </Col>
            <Col md="8">  {headerDetail.scheduleStatus} </Col>
          </Row>

          <Row>
            <Col md="4"> Filled Capacity </Col>
            <Col md="8"> {(headerDetail.filledCapacity) ? headerDetail.filledCapacity : headerDetail.grossWeight} { ' kg ' } </Col>
          </Row>          
        </Col>

        <Col md="6">
          <Row>
            <Col sm="3"> Vehilce </Col>
            <Col sm="9">{headerDetail.vehicle.no} {headerDetail.vehicle.make} {headerDetail.vehicle.model} </Col>
          </Row>

          <Row>
            <Col md="3"> </Col>
            <Col md="9"> {headerDetail.vehicle.loadWeight} {'kg'} </Col>
          </Row>

          <Row>
            <Col md="3"> Driver </Col>
            <Col md="9"> {headerDetail.driver.name} </Col>
          </Row>

          <Row>
            <Col md="3"> Helper </Col>
            <Col md="9"> {headerDetail.helper.name} </Col>
          </Row>

        </Col>
    </Row>
  );
}
 
export default ScheduleHeader;