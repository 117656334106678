import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert }  from 'react-bootstrap';

const PageMessage = () => {

    const pageMessage = useSelector(state => state.alert.pmessage);

    return (
        <> 
            { pageMessage.type && <Alert variant={pageMessage.type}>
                {pageMessage.message}
            </Alert> }
        </>
    );
}

export default PageMessage;