import React from 'react';

import { Card, ListGroup, Row, Col }  from 'react-bootstrap';
 
function ScheduleReturn({returnOthers}) {

    return (
        <>
            <Card.Header>Other Returns</Card.Header>
            <ListGroup >
                {  returnOthers &&  returnOthers.map((row, i) => 
                    <ListGroup.Item key={row.customer.id} data-index={row.customer.id} >
                        {row.customer.name} - {row.customer.city}

                        {row.items.map((item,i) =>
                            <ListGroup.Item key={i} >
                                <Row>
                                    <Col xs={9}>{item.description}</Col>
                                    <Col xs={3} style={{textAlign: "right"}}>{item.returnQuantity}</Col>
                                </Row>
                            </ListGroup.Item>
                        )}

                    </ListGroup.Item>
                )}
            </ListGroup>
        </>
    );
}

export default ScheduleReturn;