import axios from 'axios';
import { scheduleConstant } from '../constants/scheduleConstant';
import { alertAction } from './alertAction';

import authHeader from "../utils/auth-header";
import { REACT_APP_API_URL } from '../utils/applicationProperties';


export const scheduleAction = {
    initializeNew,

    getAll,
    setSelectedSchedule,
    clearScheduleDetail,
    getScheduleItems,
    updateReturnQuantity,
    updateDelivered,
    cancelSchedule,
    fetchNotscheduledTRequests,
    getPendingRequests,
    getReferenceData,
    addToListTRequest,
    removeFromListTRequest,
    setSelectedTRequests,
    setStateHeader,
    createSchedule,
    saveApproval,
    
    updateDelivery,
    setDelivered,
    setNotDelivered,
    updateEarlyPrinting,
    resetNewSchedule,

    saveAuthorizationAction,

    updateStateAdditionalInfo,
    addAdditionalInfo,

    getPossibleReturnItems,
    addReturnItem,
    removeReturnItem,
    updateReturnItem,
    updateReturnAllItem,

    search
};

function initializeNew() {
    return dispatch => {
        dispatch(success());
    };

    function success() { return { type: scheduleConstant.INIT_NEW } }
}

function getAll(location) {
    return dispatch => {
        dispatch(request());

        axios
            .get( REACT_APP_API_URL  + '/schedule/' + location.id, ) 
            .then(function (response) {
                dispatch(success(response.data.data.schedules))
            });
    };

    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success(schedules) { return { type: scheduleConstant.GETALL_SUCCESS, schedules } }
    //function failure(error) { return { type: scheduleConstant.GETALL_FAILURE, error } }
}

function setSelectedSchedule(schedule) {
    return dispatch => {
        dispatch(request());
        dispatch(success(schedule))
        dispatch(alertAction.initializePage());
    }
    
    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success(currSchedule) { return { type: scheduleConstant.SET_CURR_SCHEDULE_SUCCESS, currSchedule } }

}

function clearScheduleDetail() {
    return dispatch => {
        dispatch(request());
        dispatch(success())
        //dispatch(alertAction.initializePage());
    }
    
    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success() { return { type: scheduleConstant.CLEAR_CURR_SCHEDULE_SUCCESS } }

}


function cancelSchedule(schedule) {
    return dispatch => {

        axios
            .post( REACT_APP_API_URL  + '/schedule/'+schedule.id+'/CANCELED', {}, { headers: authHeader() }) 
            .then (function(response) {
            
                if (response.data.error === true) {
                    dispatch(alertAction.error(response.data));
                } else {
                    dispatch(success(schedule));
                    dispatch(alertAction.success(response.data));
                }
                
            }).catch(function (error) {
                // handle error
                console.log(error);
                dispatch(alertAction.error(error));
            });
        
    }

    function success(schedule) { return {type: scheduleConstant.CANCEL_SUCCESS, schedule}}
}

function getScheduleItems(tRequest) {
    return dispatch => {
        dispatch(request());

        axios.get( REACT_APP_API_URL  + '/transport/request/items/'+tRequest.requestID,)
            .then (function(response) {
                dispatch(success(response.data.data.items));
            });
    }

    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success(items) { return {type: scheduleConstant.GET_ITEMS_SUCCESS, items}}
}

function updateReturnQuantity (item) {
    //return dispatch => {
        return {type: scheduleConstant.UPDATE_ITEM_RETURN_SUCCESS, item}
    //}

}

function updateReturnAllItem (delivery) {
    //return dispatch => {
        return {type: scheduleConstant.UPDATE_ALL_ITEMS_RETURN_SUCCESS, delivery}
    //}

}

function setDelivered (requestID, delivered) {
    //return dispatch => {
        return {type: scheduleConstant.SET_DELIVERED_INFO, payload: {requestID, delivered}}
    //}

}

function setNotDelivered (requestID, delivered) {
    //return dispatch => {
        return {type: scheduleConstant.SET_NOT_DELIVERED_INFO, payload: {requestID, delivered}}
    //}

}

function updateDelivered (requestID,delivered) {
    //return dispatch => {
        return {type: scheduleConstant.UPDATE_TREQUEST_DELIVERED, payload: {requestID, delivered}}
    //}

}

function resetNewSchedule () {
    return {type: scheduleConstant.RESET_NEW, payload: {}}
}

function fetchNotscheduledTRequests(locationID) {
    return dispatch => {
        dispatch(request());

        axios.get( REACT_APP_API_URL  + '/transport/notscheduled/'+locationID,)
            .then (function(response) {
                dispatch(success(response.data.data));
            });
    }

    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success(notscheduledTRequests) { return {type: scheduleConstant.GET_NOTSCHEDULED_SUCCESS, notscheduledTRequests}}
}

function getPendingRequests(locationID) {
    return dispatch => {
        dispatch(request());

        axios.get( REACT_APP_API_URL  + '/transport/pending/'+locationID,)
            .then (function(response) {
                dispatch(success(response.data.data));
            });
    }

    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success(notscheduledTRequests) { return {type: scheduleConstant.GET_NOTSCHEDULED_SUCCESS, notscheduledTRequests}}
}

function getReferenceData(location) {
    return dispatch => {
        const requestDriver     = axios.get( REACT_APP_API_URL  + '/adminResources/DRIVER/'+location.id,{});
        const requestCleaner    = axios.get( REACT_APP_API_URL  + '/adminResources/HELPER/'+location.id,{});
        const requestVehicle    = axios.get( REACT_APP_API_URL  + '/adminVehicles/'+location.id,{});

        axios.all([requestDriver, requestCleaner, requestVehicle])
            .then(axios.spread((...responses) => {
                const responseDriver = responses[0];
                const responseCleaner = responses[1];
                const responesVehicle  = responses[2];

                dispatch(successVehicle(responesVehicle.data.data.vehicles));
                dispatch(successDriver(responseDriver.data.data.resources));
                dispatch(successHelper(responseCleaner.data.data.resources));
        })).catch(errors => {
            // react on errors.
        })
    }

    function successVehicle(vehicles) { return {type: scheduleConstant.GET_VEHICLES_SUCCESS, vehicles}}
    function successDriver(drivers) { return {type: scheduleConstant.GET_DRIVERS_SUCCESS, drivers}}
    function successHelper(helpers) { return {type: scheduleConstant.GET_HELPERS_SUCCESS, helpers}}
}

function addToListTRequest(tRequest) {
    return dispatch => {
        dispatch(request());
        dispatch(success(tRequest))
    }
    
    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success(tRequest) { return { type: scheduleConstant.ADD_TO_SELECTED_TREQUESTS_LIST_SUCCESS, tRequest } }
}

function removeFromListTRequest(tRequest) {
    return dispatch => {
        dispatch(request());
        dispatch(success(tRequest))
    }
    
    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success(tRequest) { return { type: scheduleConstant.REMOVE_FROM_SELECTED_TREQUESTS_LIST_SUCCESS, tRequest } }
}

function setSelectedTRequests(tRequests) {
    return dispatch => {
        dispatch(request());
        dispatch(success(tRequests))
    }
    
    function request() { return { type: scheduleConstant.GETALL_REQUEST } }
    function success(tRequests) { return { type: scheduleConstant.SET_SELECTED_TREQUESTS_SUCCESS, tRequests } }
}

function setStateHeader(scheduleHeader) {
    return dispatch => {
        dispatch(success(scheduleHeader))
    }

    function success(scheduleHeader) { return { type: scheduleConstant.SET_STATE_HEADER, scheduleHeader } }
}

function createSchedule(schedule) {
    return dispatch => {
        axios.post( REACT_APP_API_URL  + '/createSchedule',{header: schedule.header, tRequests:schedule.selectedTRequests, additionalInfos: schedule.additionalInfos},{ headers: authHeader() })
        .then (function(response) {
            
            if (response.data.error === true) {
                dispatch(alertAction.error(response.data));
            } else {
                dispatch(success(response.data.scheduleId));
                dispatch(alertAction.success(response.data));
            }
            
        }).catch(function (error) {
            // handle error
            console.log(error);
            dispatch(alertAction.error(error));
        });
    }

    function success(scheduleID) { return { type: scheduleConstant.CREATED_SUCCESS, scheduleID } }
    
}

function saveApproval(schedule) {
    return dispatch => {
        axios.post( REACT_APP_API_URL  + '/schedule/approval',{schedule},{ headers: authHeader() })
        .then (function(response) {
            dispatch(success(schedule));
            dispatch(alertAction.success(response.data));
            //dispatch(scheduleAction.getAll());
        })
        .catch(function (error) {
            console.log(error);
            dispatch(alertAction.error(error));
        });
    }

    function success(schedule) { return { type: scheduleConstant.APPROVAL_SUCCESS, schedule } }
}

function updateEarlyPrinting(schedule) {
    return dispatch => {
        axios.post( REACT_APP_API_URL  + '/schedule/earlyPrinting',{schedule},{ headers: authHeader() })
        .then (function(response) {
            if (response.data.error === true) {
                dispatch(alertAction.error(response.data));
            } else {
                dispatch(success(schedule));
                dispatch(alertAction.success(response.data));
            }
        
        }).catch(function (error) {
            console.log(error);
            dispatch(alertAction.error(error));
        });
    }

    function success(schedule) { return { type: scheduleConstant.EARLY_PRINTING_SUCCESS, schedule } }
}

function updateDelivery(schedule) {
    return dispatch => {
        axios.post( REACT_APP_API_URL  + '/updateSchedule',{schedule},{ headers: authHeader() })
        .then (function(response) {
            console.log(response);
            dispatch(success(response.data.data));
            dispatch(alertAction.success(response.data));
            //dispatch(scheduleAction.getAll());
        })
        .catch(function (error) {
            console.log(error);
            dispatch(alertAction.error(error));
        });
    }

    function success(data) { return { type: scheduleConstant.UPDATE_DELIVERY_SUCCESS, data } }
}

function saveAuthorizationAction (authorization) {
    return dispatch => {
        axios.post( REACT_APP_API_URL  + '/s/auth/save',{authorization},{ headers: authHeader() })
        .then (function(response) {
            if (response.data.error === true) {
                dispatch(alertAction.error(response.data));
            } else {
                console.log(response.data.schedule);
                dispatch(success(response.data.schedule, response.data.authorizations));
                dispatch(alertAction.success(response.data));
            }
        
        }).catch(function (error) {
            console.log(error);
            dispatch(alertAction.error(error));
        });
    }

    function success(schedule,authorizations) { return { type: scheduleConstant.SAVE_AUTHORIZATION_ACTION_SUCCESS, payload: {schedule,authorizations} } }
}

function getPossibleReturnItems(customer) {
    return dispatch => {
        axios.get( REACT_APP_API_URL  + '/schedule/returnableItems/'+customer,{})
        .then (function(response) {
            dispatch(success(response.data.data));
        });
    }

    function success(items) { return { type: scheduleConstant.GET_POSSIBLE_RETURN_ITEMS_SUCCESS, items } }
}

function search(pattern) {
    return dispatch => {
        axios.get(REACT_APP_API_URL + '/schedule/search/reference/'+pattern,{})
             .then(function(response) {
                 dispatch(success(response.data.data))
        });
    }

    function success(schedules) { return { type: scheduleConstant.GET_SEARCH_SUCCESS, schedules } }
}

function addReturnItem(customer,item) {
    return {type: scheduleConstant.ADD_OTHER_RETURN_SUCCESS, payload: {item,customer}}
}

function removeReturnItem(customer,item) {
    return {type: scheduleConstant.REMOVE_OTHER_RETURN_SUCCESS, payload: {item,customer}}
}

function updateReturnItem(customer,item) {
    return {type: scheduleConstant.UPDATE_OTHER_RETURN_SUCCESS, payload: {item,customer}}
}

function updateStateAdditionalInfo(additionalInfos) {
    return { type: scheduleConstant.UPDATE_STATE_ADDITIONAL_INFOS, additionalInfos }
}

function addAdditionalInfo(additionalInfo) {
    return { type: scheduleConstant.UPDATE_STATE_ADDITIONAL_INFO, additionalInfo }
}
