import { alertConstant } from '../constants/alertConstant';

export function alertReducer(state = {gmessage: {}, pmessage: {}}, action) {
    switch (action.type) {
        case alertConstant.INIT_PAGE:
            return {
                ...state,
                pmessage: {}
            }
        case alertConstant.READ_SUCCESS:
            return {
                ...state,
                pmessage: {message: action.message, type: 'success'}
            };
        case alertConstant.READ_ERROR:
            return {
                ...state,
                pmessage: {message: action.message, type: 'danger'},
            }
        default:
            return state;
    }
}