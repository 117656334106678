import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const NotDeliveredForm = ({currDelivery, updateDelivery, updateIsNotDelivered}) => { 

    const [delivery, setDelivery] = useState({...currDelivery, deliveryReasonID: 0}); 
    const [formErrors, setFormErrors] = useState({});
    const notDeliveredReasonList = useSelector(state => state.return.reasonList);

    useEffect(() => {

        if (delivery.deliveryReasonID > 0) {
            let value = notDeliveredReasonList.find(reason => Number(reason.id) === Number(delivery.deliveryReasonID));
            setDelivery({ ...delivery, deliveryReasonDesc: value.description })
        }

    }, [delivery.deliveryReasonID]);

    useEffect(() => {
        setDelivery({...currDelivery, deliveryReasonID: 0});
    }, [currDelivery]);

    const inputOnChange = event => {
        console.log(event.target)
        const { name, value } = event.target
        console.log(name + "::" + value);
        setDelivery({ ...delivery, [name]: value })
    }

    const onSubmit = event => {
        event.preventDefault();
        if (!validateForm() ) {
            updateDelivery(delivery);
        }
    }

    function validateForm () {
        let errors = {error: false};

        errors = (!(delivery.deliveryReasonID && delivery.deliveryReasonID > 0) )    ? {...errors, error: true, deliveryReasonID: "Required Information"}    : errors ;
        errors =(delivery.deliveryReasonID === "9999" && !(delivery.returnRemark && delivery.returnRemark.length > 0))   ? {...errors, error: true, returnRemark: "Required Information"}  : errors ;
        errors =(!(delivery.manualGRNNo && delivery.manualGRNNo.length > 0))   ? {...errors, error: true, manualGRNNo: "Required Information"}  : errors ;

        
        setFormErrors(errors);

        return errors.error;
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Not Delivered</Card.Title>
            <Form>

                <Form.Row>
                    <Form.Group controlId="deliveryForm.reason">
                        <Form.Label>Reason</Form.Label>
                        <Form.Control as="select" 
                            defaultValue="" 
                            name="deliveryReasonID" 
                            onChange={inputOnChange}
                            isInvalid={formErrors.deliveryReasonID} >
                            <option value="0">Choose a Reason...</option>
                            {  notDeliveredReasonList &&  notDeliveredReasonList.map((reason, i) => 
                                <option key={reason.id} value={reason.id} > {reason.description}</option>
                            )}
                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                                    {formErrors.deliveryReasonID}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group controlId="deliveryForm.remark">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control as="input" 
                            type="text" 
                            name="returnRemark"
                            value={delivery.returnRemark} 
                            onChange={inputOnChange}
                            isInvalid={formErrors.returnRemark} />
                        <Form.Control.Feedback type="invalid">
                                    {formErrors.returnRemark}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group controlId="deliveryForm.manualGRNNo">
                        <Form.Label>Manual GRN No</Form.Label>
                        <Form.Control as="input" 
                            type="text" 
                            name="manualGRNNo"
                            value={delivery.manualGRNNo} 
                            onChange={inputOnChange}
                            isInvalid={formErrors.manualGRNNo} />
                        <Form.Control.Feedback type="invalid">
                                    {formErrors.manualGRNNo}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Button variant="outline-primary" onClick={onSubmit}> Update </Button>
                {' '}
                <Button variant="outline-secondary" onClick={() => updateIsNotDelivered(false)}> Cancel </Button>
            </Form>
            </Card.Body>
        </Card>
    );
}

export default NotDeliveredForm;