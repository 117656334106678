import React, { useEffect, useState } from 'react';
import { ListGroup, Row, Col, Button, Form } from "react-bootstrap";
import authHeader from '../utils/auth-header';

const AdditionalInfoForm = ({currAdditionalInfo, saveAdditionalInfo}) => {

    const [additionalInfo, setAdditionalInfo] = useState({endMeterReading: 0});
    const [message, setMessage] = useState({});
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);


    const handleInputChange = event => {
        const { name, value } = event.target
    
        setAdditionalInfo({ ...additionalInfo, isEditing: true, [name]: value });

        setIsSaveButtonDisabled(false);
    }

    function validateForm () {
        let errors = {error: false};

        errors = (additionalInfo.endMeterReading.length <= 0)     ? {...errors, error: true, endMeterReading: "Invalid Meter Reading"}    : errors ;
        errors = (parseFloat(additionalInfo.endMeterReading) <= 0)     ? {...errors, error: true, endMeterReading: "Invalid Meter Reading"}    : errors ;
        
        setMessage(errors);

        return errors.error;
    }

    const submitSave = (event) => {
        event.preventDefault();
        
        if(!validateForm()) {
            setIsSaveButtonDisabled(true);
            setAdditionalInfo({...additionalInfo, isEditing:false});
            saveAdditionalInfo({...additionalInfo, isEditing:false});
        }
    }

    return ( 
        <>
            <Form>
                <Form.Group>
                    <Form.Label>End Meter Reading</Form.Label>
                    <Form.Control 
                        value={additionalInfo.endMeterReading} 
                        name="endMeterReading" 
                        onChange={handleInputChange} 
                        isInvalid={message.endMeterReading}
                    />
                    <Form.Control.Feedback type="invalid">
                        {message.endMeterReading}
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="outline-primary" onClick={submitSave} disabled={isSaveButtonDisabled} > OK </Button>
            </Form>
        </>
     );
}
 
export default AdditionalInfoForm;