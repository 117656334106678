import React, {  } from 'react';
import {  useSelector } from 'react-redux';


import InvoicesList from './RequestList';
import ScheduleReturn from './ScheduleReturn';

const SchedulePreview = ({tRequests}) => {
    
    const returnOthers = useSelector(state => state.schedule.returnOthers);

    return ( 
        <>
            <InvoicesList isEditable={false} tRequests={tRequests} />

            {/* <ScheduleReturn returnOthers={returnOthers} /> */}
        </>
    );
}

export default SchedulePreview;