import React, { useEffect, useState } from 'react';
import {Card, ListGroup, Row, Col, Button, Form } from "react-bootstrap";
import { useSelector } from 'react-redux';


const AuthorizationForm = ({currAuthorization, histories , formVisible, saveAuthorizationAction}) => {

    const [authorization, setAuthorization] = useState(currAuthorization);
    const [message, setMessage] = useState({});
    const [showForm, setShowForm] = useState(false);

    const authState = useSelector(state => state.auth);

    useEffect(() => {

        setShowForm(false);
        let btnLabel = '';
        let authLevel = authorization.authLevel;

        setAuthorization(currAuthorization);


        if(currAuthorization.authLevel === "11" ) {
            if(currAuthorization.authorizationStatus === currAuthorization.authLevel ) {
                btnLabel = 'Approve';
                authLevel = "10";
            } else if (currAuthorization.authorizationStatus === '10' || currAuthorization.authorizationStatus >= 20) {
                btnLabel = '';
            } else {
                btnLabel = 'Request';
            }
        }

        if(currAuthorization.authLevel === "21" ) {
            if(currAuthorization.authorizationStatus === currAuthorization.authLevel ) {
                btnLabel = 'Approve';
                authLevel = "20";
            } else if (currAuthorization.authorizationStatus === '20') {
                btnLabel = '';
            } else {
                btnLabel = 'Request';
            }
        }

        if(currAuthorization.authLevel === "31") {
            if (currAuthorization.authorizationStatus !== '10') {
                btnLabel = '';
            } else if (currAuthorization.earlyPrint === currAuthorization.authLevel ) {
                btnLabel = 'Approve';
                authLevel = "30";
            } else {
                btnLabel = 'Request';
            }
        }

        if(currAuthorization.authLevel === "41") {
            if (currAuthorization.authorizationStatus !== "10") {
                btnLabel = '';
            } else if (currAuthorization.reschedule === currAuthorization.authLevel ) {
                btnLabel = 'Approve';
                authLevel = "40";
            } else {
                btnLabel = 'Request';
            }
        }

        //if (currAuthorization.authorizationStatus.substr(1,1) !== '0' ||  
            //currAuthorization.authorizationStatus.substr(0,1) !== currAuthorization.authLevel.substr(0,1)) {
                if (btnLabel === 'Approve' && (authState.role === 'ADMIN' || authState.role === 'MANAGER')) {
                    setShowForm(true);
                } else if (btnLabel === 'Request') {
                    setShowForm(true);
                } else {
                    setShowForm(false);
                }
            
        //}

        // If Cancel Request is Placed No more other actions
        //if (currAuthorization.authorizationStatus === '21' &&  currAuthorization.authLevel === "31") {
        //    setShowForm(false);
        //}

        setAuthorization({ ...authorization, btnLabel, authLevel});
    }, [currAuthorization.authLevel,currAuthorization.authorizationStatus])

    const handleInputChange = event => {
        const { name, value } = event.target
    
        setAuthorization({ ...authorization, isEditing: true, [name]: value });
    }

    function validateForm () {
        let errors = {error: false};

        errors = (authorization.comment.length < 5)     ? {...errors, error: true, no: "Invalid Comment"}    : errors ;

        setMessage(errors);

        return errors.error;
    }

    const submitAuthorizationAction = (event) => {
        event.preventDefault();
        
        if(! validateForm()) {
            setAuthorization({...authorization, isEditing:false});
            saveAuthorizationAction({...authorization, isEditing:false});
        }
    }

    const ButtonPanel = () => {
        switch(authorization.btnLabel) {
            case 'Approve' :
                return (<Button variant="outline-primary" onClick={submitAuthorizationAction} > {authorization.btnLabel} </Button>)
            case 'Request' :
                return (<Button variant="outline-primary" onClick={submitAuthorizationAction} > {authorization.btnLabel} </Button>)
            default :
                return '';
        }
    }

    return ( 
        <>
            <ListGroup variant="flush" >  
                    { histories  ?  
                        (histories.map((history, i) =>
                            <Card className="mb-1" key={i} >
                                <Card.Body>
                                    <div className="blockquote-footer">
                                        {history.comment}
                                    </div>

                                    <Row>
                                        <Col>{history.authorizationDesc}</Col>
                                        <Col className="text-right">
                                            <div className="blockquote-footer">
                                                {history.authorizedBy} {' '} {history.authorizedDate}
                                            </div>
                                        </Col>
                                    </Row>
                                     
                          
                                </Card.Body>
                            </Card> 
                            ) 
                        )  : ( '' )
                    }
            </ListGroup>

            { showForm &&
                <Form>
                    <Form.Group>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control value={authorization.comment} 
                                        name="comment" 
                                        onChange={handleInputChange} 
                                        />
                    </Form.Group>

                    <Button variant="outline-primary" onClick={submitAuthorizationAction} > {authorization.btnLabel} </Button>

                </Form>
            }
        </>
     );
}
 
export default AuthorizationForm;