import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const deliverToList = [
    {id: 1, description: "Customer"},
    {id: 2, description: "Agent"}
];


const DeliveredForm = ({currDelivery, updateDelivery, updateIsDelivered}) => {

    const [delivery, setDelivery] = useState(currDelivery);
    const [formErrors, setFormErrors] = useState({});

    const agentList = useSelector(state => state.resource.agents);

    useEffect(() => {
        console.log("useEffect agentID");
        console.log("agentID: " + delivery.agentID);
        if (delivery.agentID > 0) {
            let value = agentList.find(agent => agent.id == delivery.agentID);
            console.log("Value: " + JSON.stringify(value)) 
            console.log("Value: " + value.description) 
            setDelivery({ ...delivery, agentName: value.name})
        }
    }, [delivery.agentID]);

    useEffect(() => {
        setDelivery(currDelivery);
    }, [currDelivery]);

    const inputOnChange = event => {
        console.log(event.target)
        const { name, value } = event.target
        console.log(name + "::" + value);
        setDelivery({ ...delivery, [name]: value})
    }

    const onSubmit = event => {
        event.preventDefault();
        if (!validateForm() ) {
            updateDelivery(delivery);
        }
    }

    function validateForm () {
        let errors = {error: false};

        errors = (!(delivery.deliverTo && delivery.deliverTo > 0) )    ? {...errors, error: true, deliverTo: "Required Information"}    : errors ;
        errors =(delivery.deliverTo === "2" && !(delivery.agentID && delivery.agentID > 0))   ? {...errors, error: true, agentID: "Required Information"}  : errors ;

        setFormErrors(errors);

        return errors.error;
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Delivered</Card.Title>
            <Form>

                <Form.Row>
                    <Form.Group controlId="deliveryForm.deliverTo">
                        <Form.Label>To </Form.Label>
                        <Form.Control as="select" 
                            defaultValue="" 
                            name="deliverTo" 
                            onChange={inputOnChange}
                            isInvalid={formErrors.deliverTo} 
                            >
                            <option value="">Choose Deliver To...</option>
                            {  deliverToList &&  deliverToList.map((reason, i) => 
                                <option key={reason.id} value={reason.id} > {reason.description}</option>
                            )}
                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                                    {formErrors.deliverTo}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

            {delivery.deliverTo === "2" ? (
                <Form.Row>
                    <Form.Group controlId="deliveryForm.agentID">
                        <Form.Label>Agent </Form.Label>
                        <Form.Control as="select" 
                            defaultValue="" 
                            name="agentID" 
                            onChange={inputOnChange}
                            isInvalid={formErrors.agentID} >
                            <option value="">Choose Agent...</option>
                            {  agentList &&  agentList.map((agent, i) => 
                                <option key={agent.id} value={agent.id} > {agent.name}</option>
                            )}
                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                                    {formErrors.agentID}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
            ) : ''
            }

                <Form.Row>
                    <Form.Group controlId="deliveryForm.remark">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control as="input" 
                            type="text" 
                            name="deliveryRemark"
                            value={delivery.deliveryRemark} 
                            onChange={inputOnChange}/>
                    </Form.Group>
                </Form.Row>

                <Button variant="outline-primary" onClick={onSubmit}> Update </Button>
                {' '}
                <Button variant="outline-secondary" onClick={() => updateIsDelivered(false)}> Cancel </Button>
            </Form>
            </Card.Body>
        </Card>
    )
}

export default DeliveredForm;