import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const ReturnForm = ({currItem, updateItem, setReturning}) => {
    const [item, setItem] = useState(currItem);
    const [formErrors, setFormErrors] = useState({});

    const returnReasonList = useSelector(state => state.return.reasonList);

    useEffect(() => {
        setItem(currItem);
        console.log(currItem);
    },[ currItem ]);

    useEffect(() => {
        console.log("useEffect returnReasonID");
        console.log("returnReasonID: " + item.returnReasonID);
        if (item.returnReasonID > 0) {
            let value = returnReasonList.find(reason => reason.id == item.returnReasonID);
            console.log("Value: " + JSON.stringify(value)) 
            console.log("Value: " + value.description) 
            setItem({ ...item, returnReasonDesc: value.description })
        }
    }, [item.returnReasonID]);

    const inputOnChange = event => {
        console.log(event.target)
        const { name, value } = event.target
        console.log(name + "::" + value);
        setItem({ ...item, [name]: value })
    }

    const onSubmit = event => {
        event.preventDefault();
        if (!validateForm()) {
            updateItem(item)
        }
    }

    function validateForm () {
        let errors = {error: false};

        errors = (parseInt(item.returnQuantity) <= 0 || parseInt(item.returnQuantity) > parseInt(item.totalQuantity) )    ? {...errors, error: true, returnQuantity: "Invalid Return Quantity"}    : errors ;
        errors = (!(item.returnReasonID && item.returnReasonID > 0) )    ? {...errors, error: true, returnReasonID: "Required Information"}    : errors ;
        errors =(item.returnReasonID === "9999" && !(item.returnRemark && item.returnRemark.length > 0))   ? {...errors, error: true, returnRemark: "Required Information"}  : errors ;
        errors =(!(item.manualGRNNo && item.manualGRNNo.length > 0))   ? {...errors, error: true, manualGRNNo: "Required Information"}  : errors ;

        setFormErrors(errors);

        return errors.error;
    }


    return (
        <Card>
            <Card.Body>
                <Card.Title>Return - {item.description} </Card.Title>
            <Form>
                <Form.Row>
                    <Form.Group controlId="returnForm.returnQuantity">
                        <Form.Label>Return Quantity</Form.Label>
                        <Form.Control as="input" 
                            type="number" 
                            name="returnQuantity"
                            value={item.returnQuantity} 
                            onChange={inputOnChange}
                            isInvalid={formErrors.returnQuantity}/>

                        <Form.Control.Feedback type="invalid">
                            {formErrors.returnQuantity}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group controlId="returnForm.reason">
                        <Form.Label>Reason</Form.Label>
                        <Form.Control as="select" defaultValue="" name="returnReasonID" onChange={inputOnChange}
                            isInvalid={formErrors.returnReasonID} >
                            <option value="">Choose a Reason...</option>
                            {  returnReasonList &&  returnReasonList.map((returnReason, i) => 
                                <option key={returnReason.id} value={returnReason.id} > {returnReason.description}</option>
                            )}
                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                            {formErrors.returnReasonID}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group controlId="returnForm.remark">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control as="input" 
                            type="text" 
                            name="returnRemark"
                            value={item.returnRemark} 
                            onChange={inputOnChange}
                            isInvalid={formErrors.returnRemark}/>

                        <Form.Control.Feedback type="invalid">
                            {formErrors.returnRemark}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group controlId="returnForm.manualGRNNo">
                        <Form.Label>Manual GRN No</Form.Label>
                        <Form.Control as="input" 
                            type="text" 
                            name="manualGRNNo"
                            value={item.manualGRNNo} 
                            onChange={inputOnChange}
                            isInvalid={formErrors.manualGRNNo} />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.manualGRNNo}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Button variant="outline-primary" onClick={onSubmit}> Update Return </Button>
                {' '}
                <Button variant="outline-secondary" onClick={() => setReturning(false)}> Cancel </Button>
            </Form>
            </Card.Body>
        </Card>
    );
}

export default ReturnForm;