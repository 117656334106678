import axios from 'axios';
import { alertAction } from './alertAction';

import authHeader from "../utils/auth-header";
import { dashboardConstant } from '../constants/dashboardConstant';

import { REACT_APP_API_URL } from '../utils/applicationProperties';

export const dashboardAction = {
    getAll
};

function getAll(location) {
    return dispatch => {
        dispatch(request());

        axios
            .get( REACT_APP_API_URL  + '/dashboard/delivery/'+location.id, { headers: authHeader() }) 
            .then(function (response) {
                dispatch(success(response.data.data))
            })
            .catch(function (error) {
                console.log(error);
                dispatch(alertAction.error(error));
            });
    };

    function request() { return { type: dashboardConstant.GETALL_REQUEST } }
    function success(dsdata) { return { type: dashboardConstant.GETALL_SUCCESS, dsdata } }
    function failure(error) { return { type: dashboardConstant.GETALL_FAILURE, error } }
}