import React, { useEffect, useState } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";

const VehicleForm = (props) => {

    const[vehicle, setVehicle] = useState(props.vehicle);
    const[readOnly, setReadOnly] = useState(true);

    const[message, setMessage] = useState({});

    useEffect(() => {
        console.log("props.vehicle --- ")
        console.log(JSON.stringify(props.vehicle));
        if ( (props.vehicle.id === "1") || (props.vehicle.status === "I")) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        } 
    },[props.vehicle]);

    const handleInputChange = event => {
        const { name, value } = event.target
    
        setVehicle({ ...vehicle, isEditing: true, [name]: value });
    }

    const handleVehicleInputChange = event => {
        const { name, value } = event.target;
        setVehicle({ ...vehicle, isEditing: true, [name]: value });   
    }

    function validateForm () {
        let errors = {error: false};

        errors = (vehicle.no.length < 5)     ? {...errors, error: true, no: "Invalid Vehicle No"}    : errors ;
        errors = (vehicle.make.length < 3)   ? {...errors, error: true, make: "Invalid Vehicle Make"}  : errors ;
        errors = (vehicle.model.length < 3)  ? {...errors, error: true, model: "Invalid Vehicle Model"} : errors ;
        errors = (vehicle.loadWeight < 10)  ? {...errors, error: true, loadWeight: "Invalid Vehicle Load Weight"} : errors ;
        errors = (vehicle.locationID <= 0)  ? {...errors, error: true, locationID: "Invalid Vehicle location"} : errors ;

        setMessage(errors);

        return errors.error;
    }

    const saveVehicle = (event) => {
        event.preventDefault();
        
        if(! validateForm()) {
            setVehicle({...vehicle, isEditing:false});
            props.saveVehicle({...vehicle, isEditing:false});
        }
    }

    return ( 
        <Card>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="vehicleNo">
                            <Form.Label>No </Form.Label>
                            <Form.Control value={vehicle.no} 
                                name="no" 
                                onChange={handleInputChange} 
                                disabled={readOnly}
                                isInvalid={message.no} />
                            <Form.Control.Feedback type="invalid">
                                {message.no}
                            </Form.Control.Feedback> 
                        </Form.Group>
                        <Form.Group as={Col} controlId="vehicleMake">
                            <Form.Label>Make</Form.Label>
                            <Form.Control value={vehicle.make} 
                                name="make" 
                                onChange={handleInputChange} 
                                readOnly={readOnly} 
                                isInvalid={message.make} />
                            <Form.Control.Feedback type="invalid">
                                {message.make}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="vehicleModel">
                            <Form.Label>Model</Form.Label>
                            <Form.Control value={vehicle.model} 
                                name="model" 
                                onChange={handleInputChange} 
                                readOnly={readOnly} 
                                isInvalid={message.model} />
                            <Form.Control.Feedback type="invalid">
                                {message.model}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="vehicleLoadWeight">
                            <Form.Label>Load Weight</Form.Label>
                            <Form.Control value={vehicle.loadWeight} 
                                name="loadWeight" 
                                onChange={handleInputChange} 
                                readOnly={readOnly} 
                                isInvalid={message.loadWeight} />
                            <Form.Control.Feedback type="invalid">
                                {message.loadWeight}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="vechicleLocation">
                        <Form.Label>Location</Form.Label>
                            <Form.Control as="select" custom name="locationID" 
                                            onChange={handleVehicleInputChange} 
                                            value={vehicle.locationID}
                                            disabled={readOnly} 
                                            isInvalid={message.locationID} >
                                <option value="">Select a Location</option>
                            {  props.locations &&  props.locations.map((rLocation, i) => 
                                <option key={rLocation.id} value={rLocation.id} > {rLocation.name} </option>
                            )}
                                <Form.Control.Feedback type="invalid">
                                    {message.locationID}
                                </Form.Control.Feedback>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="vehicleStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control as="select" custom name="status" 
                                            onChange={handleVehicleInputChange} 
                                            value={vehicle.status}
                                            disabled={readOnly} 
                                            isInvalid={message.status} >
                            
                                <option key="A" value="A" > Active </option>
                                <option key="I" value="I" > In-Active </option>
                                
                                <Form.Control.Feedback type="invalid">
                                    {message.status}
                                </Form.Control.Feedback>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Button variant="outline-primary" onClick={saveVehicle} disabled={readOnly} >Save </Button>
                    { ' ' }
                    <Button variant="outline-primary" onClick={props.closeForm} >Close </Button>
                    
                </Form>
            </Card.Body>
        </Card>
     );
}
 
export default VehicleForm;