import React from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';


const IdentityTable = (props) => {
    const {identities} = props;
    return (
        <Card>
            <Card.Body>
                {identities && identities.length > 0 ? (
                    identities.map((identitiy) => (
                        <Form.Control>
                        <Form.Label>Type</Form.Label> <Form.Text>Type Name </Form.Text>
                        </Form.Control>
                    ))
                ) : (
                    <tr>
                    <td colSpan={3}>No Resource</td>
                    </tr>
                )}
            </Card.Body>
        </Card>
    );
}

export default IdentityTable;