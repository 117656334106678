import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Card, Col, ListGroup, Row, Tab }  from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faFileSignature, faStamp, faTruck, faTrash, faEdit, faSave, faArrowRight, faPrint } from '@fortawesome/free-solid-svg-icons';

import RequestList from './RequestList';
import OtherReturnItems from './OtherReturnItems';
import SchedulePreview from './SchedulePreview';

import {scheduleAction} from '../actions/scheduleAction';
import PageMessage from '../utils/pageMessage';
import ScheduleHeader from './ScheduleHeader';
import ScheduleApproval from './ScheduleApproval';
import { alertAction } from '../actions/alertAction';
import ConfirmationModal from '../utils/ConfirmationModal';
import RibbonButtons from '../utils/RibbonButtons';
import AuthorizationForm from './AuthorizationForm';
import DispatchForm from './DispatchForm';
import ScheduleDocuments from './ScheduleDocuments';
import AdditionalInfoForm from './AdditionalInfoForm';

const ScheduleDetail = ({currSchedule, additionalInfos, returnOthers, cancelSchedule,deleveredSchedule, clearScheduleDetail}) => {

    const title = ["No Title", "Schedule Summary", "Schedule Approval", "Update Delivery Information","Update Return Collection", "Schedule Preview"]
    const [scheduleID,setScheduleID] = useState(0);
    const [currStep, setCurrStep] = useState(1);

    const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
    
    const [printVisible, setPrintVisible] = useState(true);

    const [saveVisible, setSaveVisible] = useState(false);
    const [nextVisible, setNextVisible] = useState(false);
    const [cancelVisible, setCancelVisible] = useState(false);
    
    const [approveVisible, setApproveVisible] = useState(false);
    const [earlyPrintVisible, setEarlyPrintVisible] = useState(false);
    const [requestEarlyPrintingVisible, setRequestEarlyPrintingVisible] = useState(false);
    const [approveEarlyPrintingVisible, setApproveEarlyPrintingVisible] = useState(false);
    const [reScheduleVisible, setRescheduleVisible] = useState(false);

    const [isAdditionalInfoMarked, setIsAdditionalInfoMarked] = useState(false);

    const [dispatchVisible, setDispatchVisible] = useState(false);

    const [activeTab, setActiveTab] = useState("");

    const [modalState, setModalState] = useState({show: false, title: "", function: ""});
    const handleClose = () => setModalState({...modalState, show: false, title: "", function: ""});
    const handleSuccess = () => {
        if(modalState.function === "requestEarlyPrintingApproval") {
            //saveRequestEarlyPrintingApproval();
            handleClose();
        } else if (modalState.function === "approveEarlyPrintingApproval") {
            //saveApproveEarlyPrinting();
            handleClose();
        } else if (modalState.function === "cancelSchedule") {
            cancelSchedule(currSchedule);
            handleClose();
        }
    }

    const dispatch = useDispatch();

    useEffect(() => {
        setScheduleID(currSchedule.id);
        setCurrStep(1);
        setActiveTab("#lines");
 
        /*if(currSchedule.scheduleStatusID === "24") {
            if ((parseInt(currSchedule.daysLeft) == 0) || (currSchedule.earlyPrint === "30"))
                setPrintVisible(true);
        }*/
    }, [currSchedule.id !== scheduleID]);

    useEffect(() => {
        
        //setPrintVisible(false);
        setSaveVisible(false);
        setNextVisible(false);
        setCancelVisible(false);
        setEarlyPrintVisible(false);
        //setRequestEarlyPrintingVisible(false);
        //setApproveEarlyPrintingVisible(false);
        setRescheduleVisible(false);
        setApproveVisible(false);

        setDispatchVisible(false);

        if(currSchedule.scheduleStatusID < 24) {
            setApproveVisible(true);
        }

        /*if(currSchedule.scheduleStatusID === "24") {
            if ((parseInt(currSchedule.daysLeft) == 0) || (currSchedule.earlyPrint === "30"))
                setPrintVisible(true);
        }*/

        if(currSchedule.scheduleStatusID < 25 && currStep === 6 ) {
            setSaveVisible(true);
        }

        /*if(currStep !== 2 && currStep !== 5 && parseInt(currSchedule.daysLeft) <= 0) {
            setNextVisible(true);
        }

        if(currStep !== 2 && currStep !== 5 && currSchedule.scheduleStatusID < 24 ) {
            setNextVisible(true);
        }*/

        if(currStep !== 6 && currSchedule.dispatched === "50") {
            setNextVisible(true);
        }

        if(currSchedule.scheduleStatusID <= 24) {
            setCancelVisible(true);
        }

        if(currSchedule.scheduleStatusID === "24" && parseInt(currSchedule.daysLeft) > 0 && currSchedule.earlyPrinting === 'N') {
            setRequestEarlyPrintingVisible(true);
        }

        if(currSchedule.scheduleStatusID === "24" && parseInt(currSchedule.daysLeft) > 0 && currSchedule.earlyPrinting === 'R') {
            setApproveEarlyPrintingVisible(true);
        }

        if((currSchedule.scheduleStatusID === "24" && parseInt(currSchedule.daysLeft) > 0) || currSchedule.earlyPrint === "30") {
            setEarlyPrintVisible(true);
        }

        if(currSchedule.scheduleStatusID === "24" && currSchedule.authorizationStatus === '10' && currSchedule.dispatched === "0") {
            setRescheduleVisible(true);
        }

        if(currSchedule.scheduleStatusID === "24" && 
            parseInt(currSchedule.daysLeft) == 0 && 
            currSchedule.authorizationStatus === '10' && 
            currSchedule.dispatched !== "50") 
        {
            setDispatchVisible(true);
        }

    }, [currSchedule.scheduleStatusID, 
        currSchedule.daysLeft, 
        currSchedule.earlyPrinting, 
        currSchedule.authorizationStatus,
        currSchedule.dispatched, 
        currStep]);

    const nextStep = () => {

        if (currStep === 1) {
            if (currSchedule.scheduleStatusID < 24) {
                setCurrStep(currStep + 1);
            } else {
                setCurrStep(currStep + 2);
            }
        }

        if (currStep === 3) {
            if (!isDeliveryMarked()) {
                dispatch(alertAction.error({message: 'All Invoices must be marked delivered / Not delivered', type: 'danger'}));
            } else {
                dispatch(alertAction.initializePage());
                setCurrStep(currStep + 2);    // To skip Other return activity
            }
        } 

        if (currStep === 5) {
            if (!isAdditionalInfoMarked) {
                dispatch(alertAction.error({message: 'All Additional Infomation not entered..', type: 'danger'}));
            } else {
                dispatch(alertAction.initializePage());
                setCurrStep(currStep + 1); 
            }
        } 
        
        
        
    }

    function isDeliveryMarked () {
        let allMarked = true;
        currSchedule.tRequests.map((tRequest, i) => {
            tRequest.items  ?  ( allMarked = allMarked ) : (allMarked = false) ;
        });

        return allMarked;
    }

    const save = () => {
        setIsSaveBtnDisabled(true);
        dispatch(scheduleAction.updateDelivery({
                                        scheduleID: currSchedule.id, 
                                        tRequests: currSchedule.tRequests, 
                                        additionalInfos: additionalInfos,
                                        otherItems: returnOthers}))
    }

    const saveApproval = (header = {},scheduleDate,vehicle,driver,helper,tRequests) => {
        dispatch(scheduleAction.saveApproval({id: currSchedule.id, 
            header: {isFull: (header.isFull) ? header.isFull : currSchedule.isFull, 
                    grossWeight:  (header.grossWeight ? header.grossWeight : currSchedule.filledCapacity)},
                    scheduleDate, vehicle,driver,helper,tRequests}));
    }

    /*const print = (schedule) => {
        window.open('/pdfdocengin_test/dispatchNotePDF.php?scheduleID='+schedule.id,'printDeliveryNote','height=950,width=850,top=100,left=200,scrollbars=yes,resizable');
    }*/

    /*const requestEarlyPrintingApproval = (schedule) => {
        setModalState({...modalState, show: true, title: "Request Early Printing Permission", function: "requestEarlyPrintingApproval"});
    }

    const saveRequestEarlyPrintingApproval = () => {
        console.log("saveRequestEarlyPrintingApproval");
        dispatch(scheduleAction.updateEarlyPrinting({id:currSchedule.id, earlyPrinting: 'R'}));
    }

    const approveEarlyPrinting = (schedule) => {
        setModalState({...modalState, show: true, title: "Approve Early Printing Request", function: "approveEarlyPrintingApproval"});
    }

    const saveApproveEarlyPrinting = () => {
        console.log("saveapproveEarlyPrinting");
        dispatch(scheduleAction.updateEarlyPrinting({id:currSchedule.id, earlyPrinting: 'A'}));
    }*/

    const cancelScheduleRequest = (schedule) => {
        setModalState({...modalState, show: true, title: "Schedule Cancel Request", function: "cancelSchedule"});
    }

    const saveAuthorizationAction = (authorization) => {
        console.log("saveAuthorizationAction");
        console.log(authorization);
        let overrideAuthLevel = authorization.authLevel;
        if (authorization.authLevel.substr(0,1) === '2' && currSchedule.scheduleStatusID < 24 ) {
            overrideAuthLevel = 20;
        } 
        dispatch(scheduleAction.saveAuthorizationAction({...authorization, authLevel: overrideAuthLevel, scheduleID: currSchedule.id}));
    }

    const setAdditionalInfo = (additionalInfo) => {
        setIsAdditionalInfoMarked(true);

        dispatch(scheduleAction.addAdditionalInfo(additionalInfo));
    }

    return ( 
        <> 
        
        { currStep > 0 &&
        <Card > 
            <Card.Header>
                <ScheduleHeader headerDetail={currSchedule} />
            </Card.Header>

            <Card.Body>
                <Card.Title>{title[currStep]}</Card.Title>
                <hr/>

                <PageMessage />

                {/* ******************************************************************** */}

                <Tab.Container   id="list-group-tabs-example" 
                                activeKey={activeTab} 
                                onSelect={(aKey) => setActiveTab(aKey)}>
                    <Row>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action eventKey="#lines" > Orders  </ListGroup.Item>
                                {approveVisible && <ListGroup.Item action eventKey="#edit"  > Edit </ListGroup.Item> }
                                <ListGroup.Item action eventKey="#approve"> Approve </ListGroup.Item>
                                <ListGroup.Item action disabled={!earlyPrintVisible} eventKey="#earlyPrint"> Early Print </ListGroup.Item>
                                <ListGroup.Item action disabled={!reScheduleVisible} eventKey="#reSchedule"> Re-Schedule </ListGroup.Item>
                                <ListGroup.Item action disabled={!printVisible} eventKey="#print"> Print </ListGroup.Item>
                                <ListGroup.Item action eventKey="#dispatch" disabled={!dispatchVisible}>Dispatch</ListGroup.Item>
                                {cancelVisible && <ListGroup.Item action eventKey="#cancel"> Cancel </ListGroup.Item> }
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="#lines">
                                    { currStep === 1 && <SchedulePreview tRequests={currSchedule.tRequests} /> }
                                    { currStep === 3 && <RequestList isEditable={true} tRequests={currSchedule.tRequests} /> }
                                    { currStep === 4 && <OtherReturnItems /> }
                                    { currStep === 5 && <AdditionalInfoForm saveAdditionalInfo={setAdditionalInfo} /> }
                                    { currStep === 6 && <SchedulePreview tRequests={currSchedule.tRequests} /> }
                                </Tab.Pane>
                                <Tab.Pane eventKey="#cancel">
                                    {activeTab === "#cancel" && <AuthorizationForm 
                                        currAuthorization={{comment: "", authLevel: "21", authorizationStatus: currSchedule.authorizationStatus}} 
                                        histories={currSchedule.authorizations}
                                        formVisible={cancelVisible}
                                        saveAuthorizationAction={saveAuthorizationAction}/> }
                                </Tab.Pane>
                                <Tab.Pane eventKey="#edit">
                                    <ScheduleApproval   vehicle={currSchedule.vehicle} 
                                                        driver={currSchedule.driver} 
                                                        helper={currSchedule.helper}
                                                        scheduleDate={currSchedule.scheduledDate} 
                                                        tRequests = {currSchedule.tRequests}
                                                        saveApproval={saveApproval} /> 
                                </Tab.Pane>
                                <Tab.Pane eventKey="#approve">
                                    {activeTab === "#approve" && 
                                        <AuthorizationForm 
                                            currAuthorization={{comment: "", authLevel: "11", authorizationStatus: currSchedule.authorizationStatus}} 
                                            histories={currSchedule.authorizations}
                                            formVisible={approveVisible}
                                            saveAuthorizationAction={saveAuthorizationAction}/> }
                                </Tab.Pane>
                                <Tab.Pane eventKey="#earlyPrint">
                                    {activeTab === "#earlyPrint" && 
                                        <AuthorizationForm 
                                            currAuthorization={{comment: "", authLevel: "31", 
                                                                authorizationStatus: currSchedule.authorizationStatus, 
                                                                earlyPrint: currSchedule.earlyPrint}} 
                                            histories={currSchedule.authorizations}
                                            formVisible={earlyPrintVisible}
                                            saveAuthorizationAction={saveAuthorizationAction}/> }
                                </Tab.Pane>
                                <Tab.Pane eventKey="#print">
                                    <ScheduleDocuments currSchedule={currSchedule} />
                                </Tab.Pane>

                                <Tab.Pane eventKey="#reSchedule">
                                    {activeTab === "#reSchedule" && 
                                        <AuthorizationForm 
                                            currAuthorization={{comment: "", authLevel: "41", 
                                                                    authorizationStatus: currSchedule.authorizationStatus,
                                                                    reschedule: currSchedule.reschedule
                                                                }} 
                                            histories={currSchedule.authorizations}
                                            formVisible={reScheduleVisible}
                                            saveAuthorizationAction={saveAuthorizationAction}/> }
                                </Tab.Pane>

                                <Tab.Pane eventKey="#dispatch">
                                    {activeTab === "#dispatch" && 
                                        <DispatchForm 
                                            currDispatch={{contactNo: "", dispatchStatus: currSchedule.dispatched}}
                                            saveDispatch={saveAuthorizationAction}
                                        />
                                    }
                                </Tab.Pane>

                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
{/* ******************************************************************** */}
                
                

            </Card.Body>
            <Card.Footer> {/*
            <FontAwesomeIcon icon={faSpinner} size="4x"  spin="false" />
            <button onClick={() => cancelSchedule({})} >Next</button>
            */}

            <RibbonButtons icon={faWindowClose} onClick={clearScheduleDetail} />

            { saveVisible  && <FontAwesomeIcon icon={faSave} size="3x" border  pull="right" onClick={() => save()} disabled={isSaveBtnDisabled}/>}
            
            { nextVisible && <FontAwesomeIcon icon={faArrowRight} size="3x" border  pull="right" onClick={() => nextStep()} />}
           

            {/*requestEarlyPrintingVisible && <FontAwesomeIcon icon={faFileSignature} size="3x" border  pull="right" onClick={requestEarlyPrintingApproval} />*/}
   
            {/*approveEarlyPrintingVisible &&  <FontAwesomeIcon icon={faStamp} size="3x" border  pull="right" onClick={approveEarlyPrinting} />*/ }
            </Card.Footer>
        </Card>
}
        <ConfirmationModal modalState={modalState} handleClose={handleClose} handleSuccess={handleSuccess} />
        </>
    );
}
 
export default ScheduleDetail;