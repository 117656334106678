import { scheduleAction } from '../actions/scheduleAction';
import { scheduleConstant } from '../constants/scheduleConstant';

const initialState = {
    list: [],
    canceled: [], 
    selectedTRequests:[], 
    additionalInfos: [],
    currSchedule: {id: 0},
    returnOthers: [],
    searchlist: [],
};

export function scheduleReducer(state = initialState, action) {
    switch (action.type) {
        case scheduleConstant.INIT_NEW:
            return {
                ...state,
                header: [],
                selectedTRequests: [],
            }
        case scheduleConstant.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case scheduleConstant.GETALL_SUCCESS:
            return {
                ...state,
                list: action.schedules,
                currSchedule: {id: 0},
                loading: false
            }
        case scheduleConstant.SET_CURR_SCHEDULE_SUCCESS:
            return {
                ...state,
                currSchedule: action.currSchedule,
                list: state.list.map(schedule =>
                    schedule.id === action.currSchedule.id
                    ? { ...schedule, active: true }
                    : { ...schedule, active: false }
                ),
                loading: false
            }
        case scheduleConstant.CLEAR_CURR_SCHEDULE_SUCCESS:
            return {
                ...state,
                currSchedule: {id: 0},
                list: state.list.map(schedule =>
                    schedule.active === true
                    ? { ...schedule, active: false }
                    : schedule
                ),
                loading: false
            }
        case scheduleConstant.GET_ITEMS_SUCCESS:
            return {
                ...state,
                currScheduleItems: action.items,
                loading: false
            }
        case scheduleConstant.UPDATE_ITEM_RETURN_SUCCESS:
            return {
                ...state,
                currScheduleItems: state.currScheduleItems.map(item =>
                                            item.tRequestLineID === action.item.tRequestLineID
                                            ? { ...item, 
                                                returnQuantity: action.item.returnQuantity,
                                                returnReasonID: action.item.returnReasonID,
                                                returnReasonDesc: action.item.returnReasonDesc,
                                                returnRemark: action.item.returnRemark,
                                                manualGRNNo: action.item.manualGRNNo,
                                            }
                                            : item
                                        )
            }
        case scheduleConstant.UPDATE_ALL_ITEMS_RETURN_SUCCESS:
            return {
                ...state,
                currScheduleItems: state.currScheduleItems.map(item =>
                                            item.tRequestLineID !== 0
                                            ? { ...item, 
                                                returnQuantity: item.totalQuantity,
                                                returnReasonID: action.delivery.deliveryReasonID,
                                                returnReasonDesc: action.delivery.deliveryReasonDesc,
                                                returnRemark: action.delivery.returnRemark,
                                                manualGRNNo: action.delivery.manualGRNNo,
                                            }
                                            : item
                                        )
            }
        case scheduleConstant.SET_DELIVERED_INFO: 
            return {
                ...state,
                currSchedule: {
                    ...state.currSchedule,
                    tRequests: state.currSchedule.tRequests.map(tRequest =>
                        tRequest.requestID === action.payload.requestID ?
                            {...tRequest, delivery: action.payload.delivered}
                            : tRequest
                    )
                },
                currScheduleItems: state.currScheduleItems.map(item =>
                    item.tRequestLineID !== 0
                    ? { ...item, 
                        returnQuantity: 0,
                        returnReasonID: 0,
                        returnReasonDesc: '',
                        returnRemark: '',
                        manualGRNNo: '',
                    }
                    : item
                )
            }
        case scheduleConstant.SET_NOT_DELIVERED_INFO: 
            return {
                ...state,
                currSchedule: {
                    ...state.currSchedule,
                    tRequests: state.currSchedule.tRequests.map(tRequest =>
                        tRequest.requestID === action.payload.requestID ?
                            {...tRequest, delivery: action.payload.delivered}
                            : tRequest
                    )                    
                }
            }
        case scheduleConstant.UPDATE_TREQUEST_DELIVERED: 
            return {
                ...state,
                currSchedule: {
                    ...state.currSchedule,
                    tRequests: state.currSchedule.tRequests.map(tRequest =>
                        tRequest.requestID === action.payload.requestID ?
                            {...tRequest, items: state.currScheduleItems}
                            : tRequest
                    )
                },
                currScheduleItems: []
            }
        case scheduleConstant.UPDATE_STATE_ADDITIONAL_INFOS: 
            return {
                ...state,
                additionalInfos: action.additionalInfos
            }
        case scheduleConstant.UPDATE_STATE_ADDITIONAL_INFO: 
            return {
                ...state,
                additionalInfos: [...state.additionalInfos, action.additionalInfo],
            }
        case scheduleConstant.SAVE_DELIVERY_UPDATE:
            return {
                ...state,
                updatedScheduleItems: state.currScheduleItems
            }
        case scheduleConstant.EARLY_PRINTING_SUCCESS:
            return {
                ...state,
                currSchedule:  {
                    ...state.currSchedule,
                    earlyPrinting: action.schedule.earlyPrinting,
                },
                list: state.list.map(schedule =>
                        schedule.id === action.schedule.id
                        ? { ...schedule, earlyPrinting: action.schedule.earlyPrinting }
                        : schedule
                    )
            }
        // Authorization Actions 
        case scheduleConstant.SAVE_AUTHORIZATION_ACTION_SUCCESS:
            return {
                ...state,
                currSchedule:  {
                    ...state.currSchedule,
                    referenceNo: action.payload.schedule.referenceNo,
                    authorizationStatus: action.payload.schedule.authorizationStatus,
                    scheduleStatusID: action.payload.schedule.minStatusID,
                    scheduleStatus: action.payload.schedule.scheduleStatus,
                    earlyPrint: action.payload.schedule.earlyPrint,
                    authorizations: action.payload.authorizations,
                    
                },
                list: state.list.map(schedule =>
                        schedule.id === action.payload.schedule.id
                        ? { ...schedule, 
                            referenceNo: action.payload.schedule.referenceNo,
                            authorizationStatus: action.payload.schedule.authorizationStatus,
                            scheduleStatusID: action.payload.schedule.minStatusID,
                            scheduleStatus: action.payload.schedule.scheduleStatus,
                            earlyPrint: action.payload.schedule.earlyPrint,
                            authorizations: action.payload.authorizations,
                          }
                        : schedule
                    )
            }           
        case scheduleConstant.CANCEL_SUCCESS:
            return {
                ...state,
                //canceled: [...state.canceled, action.schedule],
                /*list: state.list.map(schedule =>
                    schedule.id === action.schedule.id
                        ? { ...schedule, cancel: true }
                        : schedule
                    )*/
                list: state.list.filter(schedule => schedule.id !== action.schedule.id),
                currSchedule : {id:0},
                loading: false
            }
        case scheduleConstant.GET_NOTSCHEDULED_SUCCESS:
            return {
                ...state,
                notscheduledTRequests: action.notscheduledTRequests,
                loading: false
            }

        case scheduleConstant.GET_VEHICLES_SUCCESS:
            return {
                ...state,
                vehicles: action.vehicles,
            }

        case scheduleConstant.GET_DRIVERS_SUCCESS:
            return {
                ...state,
                drivers: action.drivers,
            }

        case scheduleConstant.GET_HELPERS_SUCCESS:
            return {
                ...state,
                helpers: action.helpers,
            }

        case scheduleConstant.ADD_TO_SELECTED_TREQUESTS_LIST_SUCCESS:
            let vGrossWeight = parseFloat(state.header.grossWeight) + parseFloat(action.tRequest.grossWeight) ;
            let vIsFull = (state.header.vehicle.loadWeight <= vGrossWeight) ? 'Y' : 'N';
            return {
                ...state,
                header: {...state.header,grossWeight: vGrossWeight, isFull: vIsFull},
                selectedTRequests: [ ...state.selectedTRequests, {...action.tRequest,delivery : {isDevered: false}}],
                notscheduledTRequests: state.notscheduledTRequests.filter(tRequest => tRequest.requestID !== action.tRequest.requestID),
                loading: false
            }
        case scheduleConstant.REMOVE_FROM_SELECTED_TREQUESTS_LIST_SUCCESS:
            let vGrossWeightRemoved = parseFloat(state.header.grossWeight) - parseFloat(action.tRequest.grossWeight) ;
            let vIsFullRemoved = (state.header.vehicle.loadWeight <= vGrossWeightRemoved) ? 'Y' : 'N';
            return {
                ...state,
                header: {...state.header,grossWeight: vGrossWeightRemoved, isFull: vIsFullRemoved},
                selectedTRequests: state.selectedTRequests.filter(tRequest => tRequest.requestID !== action.tRequest.requestID),
                notscheduledTRequests: [ ...state.notscheduledTRequests, action.tRequest],
                loading: false
            }
        case scheduleConstant.SET_SELECTED_TREQUESTS_SUCCESS:
            return {
                ...state,
                header: {vehicle: state.currSchedule.vehicle, grossWeight: state.currSchedule.filledCapacity, isFull: state.currSchedule.isFull},
                selectedTRequests: action.tRequests,
                loading: false
            }
        case scheduleConstant.SET_STATE_HEADER: 
            return {
                ...state,
                header: {...action.scheduleHeader, grossWeight: 0},
            }
        case scheduleConstant.CREATED_SUCCESS: 
            return {
                ...state,
                scheduleID: action.scheduleID
            }
        case scheduleConstant.RESET_NEW:
            return {
                ...state,
                selectedTRequests: []
            }
        case scheduleConstant.GET_POSSIBLE_RETURN_ITEMS_SUCCESS:
            return {
                ...state,
                possibleReturnItems: action.items
            }
        case scheduleConstant.ADD_OTHER_RETURN_SUCCESS:

            let returnOthers = [];
            let returnOthera = state.returnOthers.filter(returnOther => returnOther.customer.id === action.payload.customer.id);
            
            if (returnOthera.length > 0) {
                let returnOtherNew = {customer: action.payload.customer, items: [...returnOthera[0].items, action.payload.item]};

                returnOthers = state.returnOthers.map(returnOther =>
                    returnOther.customer.id === action.payload.customer.id ?
                        returnOtherNew
                        : returnOther
                )

            } else {
                returnOthers = [...state.returnOthers, {customer: action.payload.customer, items: [action.payload.item]}];
            }
        
            return {
                ...state,
                returnOthers: returnOthers,
                possibleReturnItems: state.possibleReturnItems.filter(item => item.code !== action.payload.item.code),
            }
        case scheduleConstant.REMOVE_OTHER_RETURN_SUCCESS:

            let returnOtherState = [];
            let returnOtherCustomer = state.returnOthers.filter(returnOther => returnOther.customer.id === action.payload.customer.id);
            
            if (returnOtherCustomer.length > 0) {
                let returnOtherItems = returnOtherCustomer[0].items.filter(item => item.code !== action.payload.item.code);

                if (returnOtherItems.length > 0) {
                    let returnOtherNew = {customer: action.payload.customer, items: returnOtherItems};

                    returnOtherState = state.returnOthers.map(returnOther =>
                        returnOther.customer.id === action.payload.customer.id ?
                            returnOtherNew
                            : returnOther
                    )
                } else {
                    returnOtherState = state.returnOthers.filter(returnOther => returnOther.customer.id !== action.payload.customer.id); 
                }
            }
        
            return {
                ...state,
                returnOthers: returnOtherState,
                possibleReturnItems: [...state.possibleReturnItems, action.payload.item ]
            }
        case scheduleConstant.UPDATE_OTHER_RETURN_SUCCESS:

            let returnOthersRemove = [];
            let returnOtherRemove = state.returnOthers.filter(returnOther => returnOther.customer.id === action.payload.customer.id);
            
            if (returnOtherRemove.length > 0) {
                let returnOtherItems = returnOtherRemove[0].items.map(item => 
                    item.code === action.payload.item.code ?
                        action.payload.item
                        : item);

                let returnOtherNew = {customer: action.payload.customer, items: returnOtherItems};

                returnOthersRemove = state.returnOthers.map(returnOther =>
                    returnOther.customer.id === action.payload.customer.id ?
                        returnOtherNew
                        : returnOther
                ) 
            }
        
            return {
                ...state,
                returnOthers: returnOthersRemove,
            }

        case scheduleConstant.UPDATE_DELIVERY_SUCCESS: 
            return {
                ...state,
                currSchedule: {...state.currSchedule, 
                    scheduleStatusID: action.data.scheduleStatusID,
                    scheduleStatus: action.data.scheduleStatus
                },
                scheduleID: action.scheduleID,
                list: state.list.filter(schedule => schedule.id !== action.data.scheduleID),
                loading: false,
            }

        case scheduleConstant.APPROVAL_SUCCESS: 
            return {
                ...state,
                currSchedule: {...state.currSchedule, 
                    vehicle: action.schedule.vehicle,
                    driver: action.schedule.driver,
                    helper: action.schedule.helper,
                    filledCapacity: action.schedule.header.grossWeight,
                            isFull: action.schedule.header.isFull,
                            tRequests: action.schedule.tRequests,
                },
                list: state.list.map(schedule => schedule.id === action.schedule.id?
                        {...schedule,
                            vehicle: action.schedule.vehicle,
                            driver: action.schedule.driver,
                            helper: action.schedule.helper,
                            filledCapacity: action.schedule.header.grossWeight,
                            isFull: action.schedule.header.isFull,
                            tRequests: action.schedule.tRequests,
                        }
                        : schedule
                    ),
            }
        case scheduleConstant.GET_SEARCH_SUCCESS:
            return {
                ...state,
                searchlist: action.schedules
            }
        default:
            return state
    }
}