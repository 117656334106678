import { locationConstant } from '../constants/locationConstant.js';

const initialState = {grantedList : [ ],
                        activeLocation: {id:0, name: ""}};

export function locationReducer(state = initialState, action) {
    
    switch (action.type) {
        case locationConstant.INIT:
            return {
                initialState
            };
        case locationConstant.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case locationConstant.GETALL_SUCCESS:
            return {
                ...state,
                grantedList: action.locations,
                activeLocation: action.locations.find(location => true),
                loading: false
            }
        case locationConstant.SET_ACTIVE_LOCATION_SUCCESS:
            return {
                ...state,
                activeLocation: action.location
            }
        default:
            return state
    }
}