import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Table, ListGroup, Row, Col, Breadcrumb }  from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ItemList from './ItemsList';

import { scheduleAction } from '../actions/scheduleAction';

const RequestList = ({isEditable, tRequests, loadItems, cancelSchedule,deleveredSchedule}) => {

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [currTRequest, setCurrTRequest] = useState(null);

    const updateDelivery = (pTRequest) => {
        setCurrTRequest(pTRequest);
        dispatch(scheduleAction.getScheduleItems(pTRequest));
        setIsOpen(true);
    }

    return ( 
        <>
        <ListGroup variant="flush" >  
            {   tRequests.map((tRequest, i) => 
                <ListGroup.Item key={tRequest.requestID} >
                    <Row key={tRequest.requestID}>
                        <Col> {tRequest.referenceNo1} {tRequest.referenceNo2} </Col>
                        <Col> {tRequest.customerName + "-" + tRequest.customerCity} </Col>
                        <Col> {tRequest.delivery.isDelivered ? 'Delivered' : 'Not Delivered'} </Col>
                        {isEditable && <Col> <FontAwesomeIcon icon={faEdit} pull="right"  onClick={ () => updateDelivery(tRequest)}/> </Col>}
                        
                    </Row>

                    { tRequest.items  ?  (
                    <ListGroup variant="flush" > 
                    <Breadcrumb>
                        <Table>
                            <thead>
                                <tr>
                                    <th > Item </th>
                                    <th className="text-right"> Issued </th>
                                    <th className="text-right"> Delevered </th>
                                    <th className="text-right"> Returned </th>
                                </tr>
                            </thead>

                            <tbody>
                            {tRequest.items.map((item, i) => 
                                <>
                                <tr key={item.tRequestLineID}>
                                    <td> {item.description} </td>
                                    <td className="text-right"> {item.totalQuantity} </td>
                                    <td className="text-right"> {tRequest.delivery.isDelivered ? (item.totalQuantity - item.returnQuantity) : 0} </td>
                                    <td className="text-right"> {item.returnQuantity} </td>
                                </tr>

                                {(item.returnQuantity > 0) ?
                                <tr>
                                    <td></td>
                                    <td colSpan="3">
                                        {item.returnReasonDesc} {' '} {item.returnRemark}
                                    </td>
                                </tr>
                                : ''}
                                </>
                                ) 
                            }
                            </tbody>
                        </Table>
                        </Breadcrumb>
                    </ListGroup>
                    )  : ( <div></div>  )}
                </ListGroup.Item>
                )
            }
        </ListGroup>

        {isOpen && (<ItemList isOpen={isOpen} setIsOpen={setIsOpen} currTRequest={currTRequest}  />)}
        </>
    );
}

export default RequestList;