import { returnConstant } from '../constants/returnConstant';

const initialState = {
    reasonList: [
        { id: 1, description: 'Shop Closed' },
        { id: 2, description: 'Customer Reject'},
        { id: 9999, description: 'Other' },
      ],
};

export function returnReducer(state = initialState, action) {
    switch (action.type) {
        case returnConstant.GETREASONS_SUCCESS:
            return {
                ...state,
                header: [],
                selectedTRequests: [],
            }
        default:
            return state
    }
}