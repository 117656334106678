import React, { useEffect, useState } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction } from '../../actions/alertAction';
import { resourceAction } from '../../actions/resourceAction';
import PageMessage from '../../utils/pageMessage';
import IdentityTable from './IdentityTable';
import ResourceDetail from './ResourceDetail';
import ResourceForm from './ResourceForm';
import ResourceTable from './ResourceTable';

const ResourcePage = () => {

    const locations = useSelector(state => state.location.grantedList);
    const dispatch = useDispatch();

    const initialStage = {id: 0, name: "", nic: "", type: "", locationID: 0, status: 0, isEditing: false};
    const resources = useSelector(state => state.resource.resources);

    const [currResource, setCurrResource] = useState(initialStage);

    useEffect(() => {
        dispatch(resourceAction.getAllResource());
    }, [])

    useEffect(() => {
        console.log(JSON.stringify(currResource));
    }, [currResource])

    const addResource = () => {
        setCurrResource({...initialStage, isEditing : true});
    }

    const editResource = (resource) => {
        setCurrResource({...resource, locationID: resource.location.id, isEditing : true});
    }

    const saveResource = (resource) => {
        console.log("saveResource");
        console.log(JSON.stringify(resource));
        if (resource.id === 0) {
            dispatch(resourceAction.createResource(resource));
        } else {
            dispatch(resourceAction.updateResource(resource));
        }
    }

    const closeForm = () => {
        setCurrResource(initialStage);
        dispatch(alertAction.initializePage());
    }

    return ( 
        <div>
            <Navbar className="bg-light justify-content-between" expand="lg">
                <Navbar.Brand>Resource Mainternance</Navbar.Brand>
                {(currResource.isEditing) ? '' : <Button variant="outline-info" className=" mr-sm-2" onClick={addResource} >Create</Button>}
            </Navbar>

            <PageMessage />

            {(currResource.isEditing) ?  <> <ResourceForm 
                                            resource={currResource} 
                                            locations={locations}
                                            saveResource={saveResource}
                                            closeForm={closeForm} /> 

                                            <ResourceDetail currResource={currResource} /> </>
                                    :   <ResourceTable 
                                            resources={resources} 
                                            editResource={editResource} /> 
            }

        </div>
     );
}
 
export default ResourcePage;