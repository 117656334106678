import { authConstant } from '../constants/authConstant';

const initialState = {  isActive: false,
                        user: {}};

export function authReducer(state = initialState, action) {
    
    switch (action.type) {
        case authConstant.LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case authConstant.LOGIN_SUCCESS:
            return {
                ...state,
                isActive: true,
                user: {name: action.user.name, fName: action.user.fName, lName: action.user.lName, userID: action.user.aid, email: action.user.email},
                role: action.user.role,
                loading: false
            }
        case authConstant.LOGIN_ERROR:
            return {
                ...state,
                loading: false
            }
        case authConstant.LOGOUT_SUCCESS:
            return {
                ...initialState,
                loading: false
            }
        default:
            return state
    }
}