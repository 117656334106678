import { resourceAction } from '../actions/resourceAction';
import { resourceConstant } from '../constants/resourceConstant';

const initialState = {vehicles : [],
                      drivers: [],
                      agents: []};

export function resourceReducer(state = initialState, action) {
    switch (action.type) {
        case resourceConstant.CREATE_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicles: [...state.vehicles, action.vehicle]
            }
        case resourceConstant.UPDATE_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicles: state.vehicles.map(vehicle => vehicle.id === action.vehicle.id ? action.vehicle : vehicle),
            }
        case resourceConstant.GETALL_VEHICLE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case resourceConstant.GETALL_VEHICLE_SUCCESS:
            return {
                ...state,
                vehicles: action.vehicles,
                loading: false
            }
        case resourceConstant.GETALL_VEHICLE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case resourceConstant.CREATE_RESOURCE_SUCCESS:
            return {
                ...state,
                loading: false,
                resources: [...state.resources, action.resource]
            }
        case resourceConstant.UPDATE_RESOURCE_SUCCESS:
            return {
                ...state,
                loading: false,
                resources: state.resources.map(resource => resource.id === action.resource.id ? action.resource : resource),
            }
        case resourceConstant.GETALL_RESOURCE_SUCCESS:
            return {
                ...state,
                resources: action.resources,
                loading: false
            }
        case resourceConstant.GETALL_AGENTS_SUCCESS:
            return {
                ...state,
                agents: action.agents,
                loading: false
            }
        default:
            return state
    }
}