import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ component: Component, isActive, roles = [], ...rest }) {

    const authState = useSelector(state => state.auth);

    const isAuthorized = roles.filter((role) => role === authState.role);



    return (
        <Route {...rest} render={props => {
            //if (!localStorage.getItem('sk-token')) {
            if (!isActive) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            if (isAuthorized.length < 1) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/401', state: { from: props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export default PrivateRoute;