import React, { useEffect, useState } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction } from '../../actions/alertAction';
import { resourceAction } from '../../actions/resourceAction';
import PageMessage from '../../utils/pageMessage';
import VehicleForm from './VehicleForm';
import VehicleTable from './VehicleTable';

const VehiclePage = () => {

    const locations = useSelector(state => state.location.grantedList);
    const dispatch = useDispatch();

    const initialStage = {id: 0, no: "", make: "", model: "", loadWeight: 0, locationID: 0, status: 0, isEditing: false};
    const vehicles = useSelector(state => state.resource.vehicles);

    const [currVehicle, setCurrVehicle] = useState(initialStage);

    useEffect(() => {
        dispatch(resourceAction.getAllVehicle());
    }, [])

    useEffect(() => {
        console.log(JSON.stringify(currVehicle));
    }, [currVehicle])

    const addVehicle = () => {
        setCurrVehicle({...initialStage, isEditing : true});
    }

    const editVehicle = (vehicle) => {
        setCurrVehicle({...vehicle, locationID: vehicle.location.id, isEditing : true});
    }

    const saveVehicle = (vehicle) => {
        console.log("saveVehicle");
        console.log(JSON.stringify(vehicle));
        if (vehicle.id === 0) {
            dispatch(resourceAction.createVehicle(vehicle));
        } else {
            dispatch(resourceAction.updateVehicle(vehicle));
        }
    }

    const closeForm = () => {
        setCurrVehicle(initialStage);
        dispatch(alertAction.initializePage());
    }

    return ( 
        <div>
            <Navbar className="bg-light justify-content-between" expand="lg">
                <Navbar.Brand>Vehicle Mainternance</Navbar.Brand>
                {(currVehicle.isEditing) ? '' : <Button variant="outline-info" className=" mr-sm-2" onClick={addVehicle} >Create</Button>}
            </Navbar>
    
            <PageMessage />
            {(currVehicle.isEditing) ?  <VehicleForm 
                                            vehicle={currVehicle} 
                                            locations={locations}
                                            saveVehicle={saveVehicle}
                                            closeForm={closeForm} /> 
                                    :   <VehicleTable 
                                            vehicles={vehicles} 
                                            editVehicle={editVehicle} /> 
            }
        </div>
     );
}
 
export default VehiclePage;