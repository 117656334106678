import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Badge, ListGroup, Row, Col, Navbar, Nav, ButtonToolbar, ButtonGroup, Card}  from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

import { scheduleAction } from '../actions/scheduleAction';
import { alertAction } from '../actions/alertAction';
import ScheduleDetail from '../components/ScheduleDetail';
import NewScheduleModal from '../modals/NewScheduleModal';
import { NavLink, useParams, useLocation } from 'react-router-dom';

//import LocationSelect from '../components/LocalationSelect';


const ScheduleHomePage = () => {

    const { status , pathname} = useParams();
    const location = useLocation();

    const authState = useSelector(state => state.auth);
    const schedules = useSelector(state => state.schedule.list);
    const currSchedule = useSelector(state => state.schedule.currSchedule);
    const returnOthers = useSelector(state => state.schedule.returnOthers);
    const additionalInfos = useSelector(state => state.schedule.additionalInfos);

    const [filteredSchedules,setFilteredSchedules] = useState([]);

    const activeLocation = useSelector(state => state.location.activeLocation);

    //const currScheduleItems = useSelector(state => state.schedule.currScheduleItems);
    const dispatch = useDispatch();

    const [showNewModel, setShowNewModel] = useState(false);
    const [reqCount, setReqCount] = useState({"all": 0, "draft": 0, "pa": 0, "pd": 0, "d": 0, "ppa": 0, "pc": 0});

    const handleClose = () => setShowNewModel(false);
    
    const handleShow = () => {
        setShowNewModel(true);
        dispatch(scheduleAction.initializeNew());
        dispatch(alertAction.initializePage());
    }


    useEffect(() => {
        console.log("useEffect []")
        console.log(location)
        dispatch(scheduleAction.getAll(activeLocation));
        dispatch(scheduleAction.getReferenceData(activeLocation));
    }, []);

    useEffect(() => {
        console.log("useEffect [status]")
        console.log(location)
        console.log(status);
        console.log(schedules);
        setFilteredSchedules(filterSchedules(status));
    },[status]);

    useEffect(() => {
        console.log("Schedule Changed");
        console.log(schedules);
        setFilteredSchedules(filterSchedules(status));
        setReqCount({...reqCount, all: filterSchedules("0").length > 0 ? filterSchedules("0").length : 0 
                                , draft: filterSchedules("1").length > 0 ? filterSchedules("1").length : 0 
                                , pa: filterSchedules("2").length > 0 ? filterSchedules("2").length : 0 
                                , pd: filterSchedules("3").length > 0 ? filterSchedules("3").length : 0 
                                , d: filterSchedules("4").length > 0 ? filterSchedules("4").length : 0 
                                , ppa: filterSchedules("5").length > 0 ? filterSchedules("5").length : 0 
                                , pc: filterSchedules("9").length > 0 ? filterSchedules("9").length : 0 });
        
    },[schedules]);

    const filterSchedules2 = () => {
        if (status === "0") { // All
            setFilteredSchedules(schedules);
        } else if (status === "9") { // Pending Cancelation
            setFilteredSchedules(schedules.filter(schedule => schedule.authorizationStatus === "21"))
        } else if (status === "1") { // Draft
            setFilteredSchedules(schedules.filter(schedule => schedule.scheduleStatusID < "24" && schedule.authorizationStatus === "0"))
        } else if (status === "2") { // Pending Approval
            setFilteredSchedules(schedules.filter(schedule => schedule.scheduleStatusID < "24" && schedule.authorizationStatus === "11"))
        } else if (status === "3") { // Pending Dispatch
            setFilteredSchedules(schedules.filter(schedule => schedule.scheduleStatusID === "24" && schedule.authorizationStatus === "10" && schedule.dispatched === "0"))
        } else if (status === "4") { // Dispatched
            setFilteredSchedules(schedules.filter(schedule => schedule.scheduleStatusID === "24" && schedule.authorizationStatus === "10" && schedule.dispatched === "50"))
        } else if (status === "5") { // Pending Print Approve
            setFilteredSchedules(schedules.filter(schedule => schedule.scheduleStatusID === "24" && schedule.earlyPrint === "31"))
        }
        
    }

    function filterSchedules (queryStatus) {
        if (queryStatus === "0") { // All
            return (schedules);
        } else if (queryStatus === "9") { // Pending Cancelation
            return (schedules.filter(schedule => schedule.authorizationStatus === "21"))
        } else if (queryStatus === "1") { // Draft
            return (schedules.filter(schedule => schedule.scheduleStatusID < "24" && schedule.authorizationStatus === "0"))
        } else if (queryStatus === "2") { // Pending Approval
            return (schedules.filter(schedule => schedule.scheduleStatusID < "24" && schedule.authorizationStatus === "11"))
        } else if (queryStatus === "3") { // Pending Dispatch
            return (schedules.filter(schedule => schedule.scheduleStatusID === "24" && schedule.authorizationStatus === "10" && schedule.dispatched === "0"))
        } else if (queryStatus === "4") { // Dispatched
            return (schedules.filter(schedule => schedule.scheduleStatusID === "24" && schedule.authorizationStatus === "10" && schedule.dispatched === "50"))
        } else if (queryStatus === "5") { // Pending Print Approve
            return (schedules.filter(schedule => schedule.scheduleStatusID === "24" && schedule.earlyPrint === "31"))
        }
        
    }

    const loadScheduleDetail = (currSchedule) => {
        dispatch(scheduleAction.setSelectedSchedule(currSchedule));
    }

    const cancelSchedule = (schedule) => {
        dispatch(scheduleAction.cancelSchedule(currSchedule));
    }

    const clearScheduleDetail = () => {
        dispatch(scheduleAction.clearScheduleDetail());
    }

    const deleveredSchedule = (schedule) => {
        console.log("onButtonClick : " + schedule.id);
        //setDeliveredSchedule(schedule.id);
    }

    const scheduleSync = () => {
        dispatch(scheduleAction.getAll(activeLocation));
    }

    return (  
        <>
        
        <Navbar bg="light" expand="lg">
            <Navbar.Brand ></Navbar.Brand>
            <ButtonToolbar aria-label="Schedule Toolbar">
                {(authState.role === 'ADMIN' || authState.role === 'MANAGER' || authState.role === 'EXECUTIVE') ?
                (<ButtonGroup className="mr-2" aria-label="Create New">
                    <Button variant="primary" onClick={handleShow} className="btn-block">
                        <FontAwesomeIcon icon={faEdit} />
                        <span>{ ' ' } New Schedule</span>
                    </Button>
                </ButtonGroup>) : ''}
                <ButtonGroup className="mr-2" aria-label="Second group">
                    <Button variant="outline-primary" onClick={scheduleSync}>
                        <FontAwesomeIcon icon={faSyncAlt} />
                        <span>{ ' ' } Sync</span>
                    </Button> 
                </ButtonGroup>
            </ButtonToolbar>
        </Navbar>

        <Container fluid style={{height: '90vh'}}>
            <Row>
                <Col md="2" className="m-0 p-0" style={{backgroundColor: "#f3f2f1"}}>
                    <Container  style={{height: '98vh'}}>

                    <Nav variant="pills" activeKey={status} className="flex-column" >
                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/schedule/0" eventKey="0">All 
                                <Badge className="float-right" bg="primary">{reqCount.all}</Badge>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/schedule/1" eventKey="1">Draft 
                                <Badge className="float-right" bg="secondary">{reqCount.draft}</Badge>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/schedule/2" eventKey="2">Pending Approve
                                <Badge className="float-right" bg="secondary">{reqCount.pa}</Badge>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/schedule/3" eventKey="3">Pending Dispatch
                                <Badge className="float-right" bg="secondary">{reqCount.pd}</Badge>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/schedule/4" eventKey="4">Dispatched
                                <Badge className="float-right" bg="secondary">{reqCount.d}</Badge>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/schedule/5" eventKey="5">Pending Print Approve
                                <Badge className="float-right" bg="secondary">{reqCount.ppa}</Badge>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/schedule/9" eventKey="9">Pending Cancel
                                <Badge className="float-right" bg="secondary">{reqCount.pc}</Badge>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Container>
                </Col>
                <Col md="3" className="pl-1 pr-1" style={{backgroundColor: "#faf9f8"}}>
                     
                    <ListGroup style={{height: "98%", marginBottom: "2px", overflowY:"auto"}}>
                        {  filteredSchedules &&  filteredSchedules.map((e, i) => 
                            (e.scheduleStatusID <= 24) ?
                            <ListGroup.Item action 
                                variant = {(e.scheduleStatusID === "24" && e.authorizationStatus === "10" && e.dispatched === "0" && e.daysLeft < 0 ) ? "danger" :  ""}
                                key={e.id} data-index={e.id} onClick={() => loadScheduleDetail(e)} active={e.active}>
                                <Row>
                                    {e.referenceNo} 
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="blockquote-footer">
                                            {e.scheduledDate}
                                        </div>
                                    </Col>
                                    <Col className="text-right">
                                        <div className="blockquote-footer">
                                            {e.route}
                                        </div>
                                    </Col>
                                </Row>
                                
                            </ListGroup.Item>
                            : ''
                        )}
                    </ListGroup>
                </Col>
                <Col  md="7" className="p-1">
                      
                    {currSchedule.id > 0 && (<ScheduleDetail   currSchedule={currSchedule} 
                                                        additionalInfos={additionalInfos}
                                                        returnOthers={returnOthers}
                                                        cancelSchedule={cancelSchedule} 
                                                        clearScheduleDetail={clearScheduleDetail}
                    deleveredSchedule={deleveredSchedule}/>)}

                    <br/>

                </Col>
            </Row>
        </Container> 
                    
            <NewScheduleModal show={showNewModel} handleClose={handleClose} />
        </> 
    );
}
 
export default ScheduleHomePage;