import axios from 'axios';
import { customerConstant } from '../constants/customerConstant';

import { REACT_APP_API_URL } from '../utils/applicationProperties';

export const customerAction = {
    getAll,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        axios
            .get( REACT_APP_API_URL  + '/customerList', ) 
            .then(function (response) {
                dispatch(success(response.data.data))
            });
    };

    function request() { return { type: customerConstant.GETALL_REQUEST } }
    function success(customers) { return { type: customerConstant.GETALL_SUCCESS, customers } }
    //function failure(error) { return { type: scheduleConstant.GETALL_FAILURE, error } }
}