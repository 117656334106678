import React from 'react';
import { Table, Button } from 'react-bootstrap';

const ResourceTable = (props) => {
    const {resources} = props;

    return ( 
        <Table>
            <thead>
                <tr>
                    <th>Location</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                {resources && resources.length > 0 ? (
                    resources.map((resource) => (
                        <tr key={resource.id}>
                            <td>{resource.location.name}</td>
                            <td>{resource.name}</td>
                            <td>{resource.type}</td>
                            <td className="text-right">
                            <Button variant="outline-primary" onClick={() => props.editResource(resource)}>Edit</Button> { ' ' }
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                    <td colSpan={3}>No Resource</td>
                    </tr>
                )}
            </tbody>
        </Table>
     );
}
 
export default ResourceTable;