export const resourceConstant = {
    CREATE_VEHICLE_SUCCESS: 'VEHICLE_CREATE_SUCCESS',
    CREATE_VEHICLE_ERROR:   'VEHICLE_CREATE_ERROR',
    UPDATE_VEHICLE_SUCCESS: 'VEHICLE_UPDATE_SUCCESS',
    UPDATE_VEHICLE_ERROR:   'VEHICLE_UPDATE_ERROR',
    GETALL_VEHICLE_REQUEST: 'VEHICLE_GETALL_REQUEST',
    GETALL_VEHICLE_SUCCESS: 'VEHICLE_GETALL_SUCCESS',

    CREATE_RESOURCE_SUCCESS: 'RESOURCE_CREATE_SUCCESS',
    CREATE_RESOURCE_ERROR:   'RESOURCE_CREATE_ERROR',
    UPDATE_RESOURCE_SUCCESS: 'RESOURCE_UPDATE_SUCCESS',
    UPDATE_RESOURCE_ERROR:   'RESOURCE_UPDATE_ERROR',
    GETALL_RESOURCE_REQUEST: 'RESOURCE_GETALL_REQUEST',
    GETALL_RESOURCE_SUCCESS: 'RESOURCE_GETALL_SUCCESS',

    GETALL_AGENTS_SUCCESS: 'AGENTS_GETALL_SUCCESS',

}