import React, { useEffect, useState } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";

const VehicleFormOth = (props) => {

    const[vehicle, setVehicle] = useState(props.vehicle);
    const[vehicles] = useState(props.vehicles);
    const[readOnly, setReadOnly] = useState(true);

    const[message, setMessage] = useState({});

    useEffect(() => {
        vehicle.id == 1 ? setReadOnly(true): setReadOnly(false);

        validateForm();
    },[vehicle])

    const handleInputChange = event => {
        const { name, value } = event.target
    
        setVehicle({ ...vehicle, isEditing: true, [name]: value });
        props.updateVehicle({ ...vehicle, isEditing: true, [name]: value });
    }

    const handleVehicleInputChange = event => {
        const { name, value } = event.target;
        if(value != "") {
            let newVehicle = vehicles.filter(vehicle => vehicle.id === value)[0];
            setVehicle({...newVehicle, isEditing: true});
        }
        
    }

    function validateForm () {
        let errors = {error: false};

        errors = (vehicle.id < 1 )           ? {...errors, error: true, no: "Invalid Vehicle"}    : errors ;
        errors = (vehicle.no.length < 5)     ? {...errors, error: true, no: "Invalid Vehicle No"}    : errors ;
        errors = (vehicle.make.length < 3)   ? {...errors, error: true, make: "Invalid Vehicle Make"}  : errors ;
        errors = (vehicle.model.length < 3)  ? {...errors, error: true, model: "Invalid Vehicle Model"} : errors ;

        setMessage(errors);

        return errors.error;
    }

    const updateVehicle = (event) => {
        event.preventDefault();
        
        if(! validateForm()) {
            setVehicle({...vehicle, isEditing:false});
            props.updateVehicle({...vehicle, isEditing:false});
        }
    }

    const onChecked = (event) => {
        setReadOnly(event.target.checked);
        props.updateVehicle({...vehicle, isEditing:true});
        if (event.target.checked) {
            setVehicle({ ...vehicle, isEditing: true, id: 1, no: "", make: "", model: "" });
        }
    }

    return ( 
        <Card>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="vehicleNo">
                            <Form.Label>No </Form.Label>
                            <Form.Control value={vehicle.no} 
                                name="no" 
                                onChange={handleInputChange} 
                                readOnly={!readOnly}
                                isInvalid={message.no} />
                            <Form.Control.Feedback type="invalid">
                                {message.no}
                            </Form.Control.Feedback> 
                        </Form.Group>
                        <Form.Group as={Col} controlId="vehicleMake">
                            <Form.Label>Make</Form.Label>
                            <Form.Control value={vehicle.make} 
                                name="make" 
                                onChange={handleInputChange} 
                                readOnly={!readOnly} 
                                isInvalid={message.make} />
                            <Form.Control.Feedback type="invalid">
                                {message.make}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="vehicleModel">
                            <Form.Label>Model</Form.Label>
                            <Form.Control value={vehicle.model} 
                                name="model" 
                                onChange={handleInputChange} 
                                readOnly={!readOnly} 
                                isInvalid={message.model} />
                            <Form.Control.Feedback type="invalid">
                                {message.model}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group id="vehicleThirdParty">
                        <Form.Check type="checkbox" checked={readOnly} label="Third Party Vehicle" name="thirdParty" 
                            onChange={onChecked}/>
                    </Form.Group>

                    <Form.Group controlId="registeredVehicle">
                        <Form.Control as="select" custom name="vehicle" 
                                        onChange={handleVehicleInputChange} 
                                        value={vehicle.id}
                                        disabled={readOnly} >
                            <option value="">Select a vehicle</option>
                        {  vehicles &&  vehicles.map((rVehicle, i) => 
                            <option key={rVehicle.id} value={rVehicle.id} > {rVehicle.no} {rVehicle.make} {rVehicle.model}</option>
                        )}
                        </Form.Control>
                    </Form.Group>

                    {vehicle.isEditing && <Button onClick={updateVehicle} >Save Change</Button> }
                    
                </Form>
            </Card.Body>
        </Card>
     );
}
 
export default VehicleFormOth;