import React, { useState } from 'react';
import { Button, Modal } from "react-bootstrap";

const ConfirmationModal = (props) => {
    const {title, show } = props.modalState;
    const { handleClose, handleSuccess } = props;

    return ( 
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton >{title}</Modal.Header>

            <Modal.Body>
                <p>Please confirm to proceed</p>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={handleSuccess}>Confirm</Button>
            </Modal.Footer>
        </Modal>
     );
}
 
export default ConfirmationModal;