import axios from 'axios';
import { resourceConstant } from '../constants/resourceConstant';
import authHeader from '../utils/auth-header';
import { alertAction } from './alertAction';

import { REACT_APP_API_URL } from '../utils/applicationProperties';

export const resourceAction = {
    createVehicle,
    updateVehicle,
    getAllVehicle,

    createResource,
    updateResource,
    getAllResource,

    getAllAgents,
};

function createVehicle(vehicle) {
    return dispatch => {

        dispatch(request());
        
        axios.post( REACT_APP_API_URL  + '/adminVehicles/create', vehicle, { headers: authHeader() }) 
            .then(function (response) {
                if (response.data.error == true) {
                    dispatch(alertAction.error(response.data));
                } else {
                    dispatch(success(response.data.data));
                    dispatch(alertAction.success(response.data));
                }  
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                dispatch(alertAction.error(error));
            });

    };

    function request() { return { type: resourceConstant.GETALL_VEHICLE_REQUEST } }
    function success(vehicle) { return { type: resourceConstant.CREATE_VEHICLE_SUCCESS, vehicle}}
    function error(error) { return { type: resourceConstant.CREATE_VEHICLE_ERROR, error}}
}

function updateVehicle(vehicle) {
    return dispatch => {

        dispatch(request());
        
        axios.post( REACT_APP_API_URL  + '/adminVehicles/update', vehicle, { headers: authHeader() }) 
            .then(function (response) {
                if (response.data.error == true) {
                    dispatch(alertAction.error(response.data));
                } else {
                    dispatch(success(response.data.data));
                    dispatch(alertAction.success(response.data));
                }  
            })
            .catch(function (error) {
                console.log(error);
                dispatch(alertAction.error(error));
            });

    };

    function request() { return { type: resourceConstant.GETALL_VEHICLE_REQUEST } }
    function success(vehicle) { return { type: resourceConstant.UPDATE_VEHICLE_SUCCESS, vehicle}}
    function error(error) { return { type: resourceConstant.UPDATE_VEHICLE_ERROR, error}}
}

function getAllVehicle() {
    return dispatch => {
        dispatch(request());

        axios
            .get( REACT_APP_API_URL  + '/adminVehicles', ) 
            .then(function (response) {
                dispatch(success(response.data.data.vehicles))
            });
    };

    function request() { return { type: resourceConstant.GETALL_VEHICLE_REQUEST } }
    function success(vehicles) { return { type: resourceConstant.GETALL_VEHICLE_SUCCESS, vehicles } }
    //function failure(error) { return { type: scheduleConstant.GETALL_FAILURE, error } }
}

function createResource(resource) {
    return dispatch => {

        dispatch(request());
        
        axios.post( REACT_APP_API_URL  + '/adminResources/create', resource, { headers: authHeader() }) 
            .then(function (response) {
                if (response.data.error == true) {
                    dispatch(alertAction.error(response.data));
                } else {
                    dispatch(success(response.data.data));
                    dispatch(alertAction.success(response.data));
                }  
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                dispatch(alertAction.error(error));
            });

    };

    function request() { return { type: resourceConstant.GETALL_RESOURCE_REQUEST } }
    function success(resource) { return { type: resourceConstant.CREATE_RESOURCE_SUCCESS, resource}}
    function error(error) { return { type: resourceConstant.CREATE_RESOURCE_ERROR, error}}
}

function updateResource(resource) {
    return dispatch => {

        dispatch(request());
        
        axios.post( REACT_APP_API_URL  + '/adminResources/update', resource, { headers: authHeader() }) 
            .then(function (response) {
                if (response.data.error == true) {
                    dispatch(alertAction.error(response.data));
                } else {
                    dispatch(success(response.data.data));
                    dispatch(alertAction.success(response.data));
                }  
            })
            .catch(function (error) {
                console.log(error);
                dispatch(alertAction.error(error));
            });

    };

    function request() { return { type: resourceConstant.GETALL_RESOURCE_REQUEST } }
    function success(resource) { return { type: resourceConstant.UPDATE_RESOURCE_SUCCESS, resource}}
    function error(error) { return { type: resourceConstant.UPDATE_RESOURCE_ERROR, error}}
}

function getAllResource() {
    return dispatch => {
        dispatch(request());

        axios
            .get( REACT_APP_API_URL  + '/adminResources', ) 
            .then(function (response) {
                dispatch(success(response.data.data.resources))
            });
    };

    function request() { return { type: resourceConstant.GETALL_RESOURCE_REQUEST } }
    function success(resources) { return { type: resourceConstant.GETALL_RESOURCE_SUCCESS, resources } }
    //function failure(error) { return { type: scheduleConstant.GETALL_FAILURE, error } }
}


function getAllAgents() {
    return dispatch => {
        dispatch(request());

        axios
            .get( REACT_APP_API_URL  + '/agent/0', ) 
            .then(function (response) {
                dispatch(success(response.data.data.agents))
            });
    };

    function request() { return { type: resourceConstant.GETALL_RESOURCE_REQUEST } }
    function success(agents) { return { type: resourceConstant.GETALL_AGENTS_SUCCESS, agents } }
    //function failure(error) { return { type: scheduleConstant.GETALL_FAILURE, error } }
}