import React, { useEffect, useState } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";

const ResourceForm = (props) => {

    const[resource, setResource] = useState(props.resource);
    const[readOnly, setReadOnly] = useState(true);

    const[message, setMessage] = useState({});

    useEffect(() => {
        console.log("props.resource --- ")
        console.log(JSON.stringify(props.resource));
        if ( (props.resource.id === "1") || (props.resource.status === "I")) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        } 
    },[props.resource]);

    const handleInputChange = event => {
        const { name, value } = event.target
    
        setResource({ ...resource, isEditing: true, [name]: value });
    }

    const handleVehicleInputChange = event => {
        const { name, value } = event.target;
        setResource({ ...resource, isEditing: true, [name]: value });   
    }

    function validateForm () {
        console.log("validate");
        console.log(resource);

        let errors = {error: false};

        errors = (resource.name.length < 3)     ? {...errors, error: true, name: "Invalid Resource name"}    : errors ;
        errors = (resource.nic.length < 10)   ? {...errors, error: true, nic: "Invalid NIC No"}  : errors ;
        errors = (resource.type.length <= 0)  ? {...errors, error: true, type: "Invalid Resource Type"} : errors ;
        errors = (resource.locationID <= 0)  ? {...errors, error: true, locationID: "Invalid Resource location"} : errors ;

        setMessage(errors);

        return errors.error;
    }

    const saveResource = (event) => {
        event.preventDefault();
        
        if(! validateForm()) {
            setResource({...resource, isEditing:false});
            props.saveResource({...resource, isEditing:false});
        }
    }

    return ( 
        <Card>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="resourceName">
                            <Form.Label>Name </Form.Label>
                            <Form.Control value={resource.name} 
                                name="name" 
                                onChange={handleInputChange} 
                                disabled={readOnly}
                                isInvalid={message.name} />
                            <Form.Control.Feedback type="invalid">
                                {message.name}
                            </Form.Control.Feedback> 
                        </Form.Group>
                        <Form.Group as={Col} controlId="resourceNIC">
                            <Form.Label>NIC</Form.Label>
                            <Form.Control value={resource.nic} 
                                name="nic" 
                                onChange={handleInputChange} 
                                readOnly={readOnly} 
                                isInvalid={message.nic} />
                            <Form.Control.Feedback type="invalid">
                                {message.nic}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="resourceType">
                            <Form.Label>Type</Form.Label>
                            <Form.Control as="select" custom name="type" 
                                            onChange={handleVehicleInputChange} 
                                            value={resource.type}
                                            disabled={readOnly} 
                                            isInvalid={message.type} >
                                <option value="">Select resource Type</option>
                                <option key="DRIVER" value="DRIVER" > Driver </option>
                                <option key="HELPER" value="HELPER" > Helper </option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {message.type}
                            </Form.Control.Feedback>
                        </Form.Group>
                    

                        <Form.Group as={Col} controlId="resourceLocation">
                        <Form.Label>Location</Form.Label>
                            <Form.Control as="select" custom name="locationID" 
                                            onChange={handleVehicleInputChange} 
                                            value={resource.locationID}
                                            disabled={readOnly} 
                                            isInvalid={message.locationID} >
                                <option value="">Select a Location</option>
                            {  props.locations &&  props.locations.map((rLocation, i) => 
                                <option key={rLocation.id} value={rLocation.id} > {rLocation.name} </option>
                            )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {message.locationID}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="vehicleStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control as="select" custom name="status" 
                                            onChange={handleVehicleInputChange} 
                                            value={resource.status}
                                            disabled={readOnly} 
                                            isInvalid={message.status} >
                            
                                <option key="A" value="A" > Active </option>
                                <option key="I" value="I" > In-Active </option>
                                
                                <Form.Control.Feedback type="invalid">
                                    {message.status}
                                </Form.Control.Feedback>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Button variant="outline-primary" onClick={saveResource} disabled={readOnly} >Save </Button>
                    { ' ' }
                    <Button variant="outline-primary" onClick={props.closeForm} >Close </Button>
                    
                </Form>
            </Card.Body>
        </Card>
     );
}
 
export default ResourceForm;