export default function authHeader() {
    //const user = JSON.parse(localStorage.getItem("sk-token"));
    const token = localStorage.getItem("sk-token");

    //if (user && user.accessToken) {
    if (token) {
      // For Spring Boot back-end
      // return { Authorization: "Bearer " + user.accessToken };
  
      // for Node.js Express back-end
      //return { "x-access-token": user.accessToken };
      return { "Authorization": token, 'Content-Type': 'application/json' };
    } else {
      return {};
    }
  }