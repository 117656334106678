import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';


const RibbonButtons = (props) => {
    return (  
        <>
        {/*<Card style={{ width: '9rem' }}>
            <FontAwesomeIcon icon={props.icon} onClick={props.onClick} size="3x"  pull="left" />
            <Card.Body>
  
            <Card.Text >Close</Card.Text>

            </Card.Body>
    </Card>*/}
    <FontAwesomeIcon icon={props.icon} onClick={props.onClick} size="3x"  border pull="left" />
    </>
    );
}
 
export default RibbonButtons;