import React, { useEffect } from 'react';
import { Button, Card, Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { scheduleAction } from '../actions/scheduleAction';

const SearchResultPage = ({location}) => {

    const schedules = useSelector(state => state.schedule.searchlist.schedules);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log("searchText changed")
        dispatch(scheduleAction.search(location.state.searchText));
    },[location.state.searchText]);

    return (
        <Container fluid className='mt-2'>
            <Card>
                <Card.Body>
                    <Card.Title>Search Result 
                
                        
        
                    </Card.Title>

                    <Table>
                        <thead>
                            <tr>
                                <th>Delivery #</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            { schedules  ?  
                                    (schedules.map((schedule, i) => 
                                        <tr>
                                            <td>{schedule.referenceNo}</td>
                                            <td>{schedule.scheduledDate}</td>
                                            <td>{schedule.scheduleStatus}</td>
                                            <td><Button> ... </Button></td>
                                        </tr>     
                                    )
                                    )  : (
                                        <p> No Schedule </p>
                                    )
                            }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default SearchResultPage;