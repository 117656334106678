import React from 'react';
import { Card }  from 'react-bootstrap';

const TileButton = ({ selected, onClick, label }) => {
    
    return (
        <Card border="primary" bg={selected === true ? "primary" : ""} className="mr-1" tag="a" onClick={onClick} style={{ cursor: "pointer" }}>
            <Card.Body>{label}</Card.Body>
        </Card>
    )
}

export default TileButton;