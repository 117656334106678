import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import LoadingOverlay from 'react-loading-overlay';

import TopNavBar from './layout/TopNavBar';
import DashboardPage from './pages/DashboardPage';
import ScheduleHomePage from './pages/ScheduleHomePage';
import { locationAction } from './actions/locationAction';
import AdminPage from './pages/AdminPage';
import PrivateRoute from './utils/PrivateRoute';
import Login from './pages/login/LoginPage';
import { dashboardAction } from './actions/dashboardAction';
import { scheduleAction } from './actions/scheduleAction';
import PlanPage from './pages/PlanPage';
import { authAction } from './actions/authAction';
import SearchResultPage from './pages/SearchResultPage';
import { Container } from 'react-bootstrap';

function App(props) {
  const isLoading = useSelector(state => state.schedule.loading);
  const locationState = useSelector(state => state.location);
  const authState = useSelector(state => state.auth);
  const isActive = useSelector(state => state.auth.isActive);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    console.log("isActive : " + isActive)
    if(isActive === true) {
      dispatch(locationAction.getAllUserLocation());
    }
  },[isActive]);

  const handleLocationChange = (value)  => {

    dispatch(locationAction.setActiveLocation(value));

    function getPosition(string, subString, index) {
      return string.split(subString, index).join(subString).length;
    }

    switch(location.pathname.substr(0,getPosition(location.pathname, '/', 2))) {
      case '/':
        dispatch(dashboardAction.getAll(value));
        return ;
      case '/schedule':
        console.log(" In Schedule")
        dispatch(scheduleAction.getAll(value));
        return;
      case '/plan' :
        dispatch(scheduleAction.getPendingRequests(value.id));
      default:
        return;
    }

  }

  /*if (!isActive) {
    console.log("isTokenValid triggered");
    dispatch(authAction.isTokenValid());
  }*/

  const logout = () => {
    dispatch(authAction.logout());
  }

  return (
    <>
      <TopNavBar isActive={isActive} authState={authState} locationState={locationState} handleLocationChange={handleLocationChange} logout={logout}/>

      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading your content...'
        >
      <Container className="p-0" style={{marginTop: "56px"}} fluid>
      <Switch>
        <PrivateRoute isActive={isActive} exact path="/" component={DashboardPage} roles={['EXECUTIVE','USER','MANAGER','ADMIN']}/>
        <PrivateRoute isActive={isActive} path="/schedule/:status" component={ScheduleHomePage} roles={['EXECUTIVE','USER','MANAGER','ADMIN']} />
        <PrivateRoute isActive={isActive} path="/plan" component={PlanPage} roles={['EXECUTIVE','USER','MANAGER','ADMIN']} />
        <PrivateRoute isActive={isActive} path="/admin" component={AdminPage} roles={['ADMIN']} />
        <PrivateRoute isActive={isActive} path="/search" component={SearchResultPage} roles={['EXECUTIVE','USER','MANAGER','ADMIN']}/>
        <Route path="/login" component={Login} />
        {/*<Redirect from="*" to="/" />*/}

    </Switch>
    </Container>
    </LoadingOverlay>
    </>
  );
}
/*
        <Route path="/admin" component={AdminPage} />
        <Route component={PageNotFound} />
        */

export default App;
