export const scheduleConstant = {
    INIT_NEW: 'SCHEDULE_INIT_NEW',
    
    GETALL_REQUEST: 'SCHEDULE_GETALL_REQUEST',
    GETALL_SUCCESS: 'SCHEDULE_GETALL_SUCCESS',

    GET_SEARCH_SUCCESS: 'SCHEDULE_GET_SEARCH_SUCCESS',
    
    GET_ITEMS_SUCCESS: 'SCHEDULE_GET_ITEMS_SUCCESS',
    UPDATE_ITEM_RETURN_SUCCESS: 'SCHEDULE_UPDATE_ITEM_RETURN_SUCCESS',
    SAVE_DELIVERY_UPDATE: 'SCHEDULE_SAVE_DELIVERY_UPDATE',
    PREVIEW_DELIVERY_UPDATE: 'SCHEDULE_PREVIEW_DELIVERY_UPDATE',
    UPDATE_TREQUEST_DELIVERED: 'UPDATE_TREQUEST_DELIVERED',

    SET_CURR_SCHEDULE_SUCCESS: 'SCHEDULE_SET_CURRENT_SUCCESS',
    CANCEL_SUCCESS: 'SCHEDULE_CANCEL_SUCCESS',

    GET_NOTSCHEDULED_SUCCESS: 'SCHEDULE_GET_NOTSCHEDULED_SUCCESS',

    GET_VEHICLES_SUCCESS: 'SCHEDULE_GET_VEHICLES_SUCCESS',
    GET_DRIVERS_SUCCESS: 'SCHEDULE_GET_DRIVERS_SUCCESS',
    GET_HELPERS_SUCCESS: 'SCHEDULE_GET_HELPERS_SUCCESS',

    SET_STATE_HEADER: 'SCHEDULE_SET_STATE_HEADER',
    
    ADD_TO_SELECTED_TREQUESTS_LIST_SUCCESS: 'SCHEDULE_ADD_TO_SELECTED_TREQUESTS_LIST_SUCCESS',
    REMOVE_FROM_SELECTED_TREQUESTS_LIST_SUCCESS: 'SCHEDULE_REMOVE_FROM_SELECTED_TREQUESTS_LIST_SUCCESS',
    SET_SELECTED_TREQUESTS_SUCCESS: 'SCHEDULE_SET_SELECTED_TREQUESTS_SUCCESS',

    CREATED_SUCCESS: 'SCHEDULE_CREATED_SUCCESS',
    RESET_NEW: 'SCHEDULE_RESET_NEW',

    APPROVAL_SUCCESS: 'SCHEDULE_APPROVAL_SUCCESS',

    GET_POSSIBLE_RETURN_ITEMS_SUCCESS: 'SCHEDULE_GET_POSSIBLE_RETURN_ITEMS_SUCCESS',
    ADD_OTHER_RETURN_SUCCESS: 'SCHEDULE_ADD_OTHER_RETURN_SUCCESS',
    REMOVE_OTHER_RETURN_SUCCESS: 'SCHEDULE_REMOVE_OTHER_RETURN_SUCCESS',
    UPDATE_OTHER_RETURN_SUCCESS: 'SCHEDULE_UPDATE_OTHER_RETURN_SUCCESS',
    UPDATE_ALL_ITEMS_RETURN_SUCCESS: 'SCHEDULE_UPDATE_ALL_ITEMS_RETURN_SUCCESS',

    UPDATE_DELIVERY_SUCCESS: 'SCHEDULE_UPDATE_DELIVERY_SUCCESS',
    SET_DELIVERED_INFO: 'SCHEDULE_SET_DELIVERED_INFO',
    SET_NOT_DELIVERED_INFO: 'SCHEDULE_SET_NOT_DELIVERED_INFO',

    CLEAR_CURR_SCHEDULE_SUCCESS: 'SCHEDULE_CLEAR_CURR_SCHEDULE_SUCCESS',

    UPDATE_STATE_ADDITIONAL_INFO: 'SCHEDULE_UPDATE_STATE_ADDITIONAL_INFO',
    UPDATE_STATE_ADDITIONAL_INFOS: 'SCHEDULE_UPDATE_STATE_ADDITIONAL_INFOS',

    SAVE_AUTHORIZATION_ACTION_SUCCESS: 'SCHEDULE_SAVE_AUTHORIZATION_ACTION_SUCCESS',
    
    EARLY_PRINTING_SUCCESS: 'SCHEDULE_EARLY_PRINTING_SUCCESS',
};