import React from 'react';
import styled from 'styled-components';

import { Badge, Card, Col, Row } from 'react-bootstrap';

export const CardFooter = styled(Card.Footer)`
  background-color: red;
  opacity: 0.6 !important;
`;


const TopCard = ({ title, aTitle, aTotal, bTitle, bTotal }) => {
    return (
      <Card className="ml-2 text-center" style={{ width: '18rem' }}>
        <Card.Body>
            <Row><Col>
          <Card.Title>{aTotal}</Card.Title>
          <Card.Text>{aTitle}</Card.Text>
          </Col>
          <Col>
          <Card.Title>{bTotal}</Card.Title>
          <Card.Text>{bTitle}</Card.Text>
          </Col>
          </Row>
     
        </Card.Body>

        <Card.Footer className="bg-transparent">
            <Card.Title>{title}</Card.Title>
        </Card.Footer>
      </Card>
    );
  };

  export default TopCard;