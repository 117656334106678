import React, { useEffect } from 'react';

import { Link } from "react-router-dom";

import { Nav, Navbar, NavDropdown} from 'react-bootstrap';

import { REACT_APP_STAGE } from '../utils/applicationProperties';

const TopNavBar = ({isActive, authState, locationState, handleLocationChange, logout}) => {

  const SESSION_USER_OBJ = authState.user;

  const usrName = SESSION_USER_OBJ.fName;
  const { grantedList, activeLocation } = locationState;

  useEffect(() => {

  },[activeLocation])


    return ( 
    <Navbar bg="dark" variant="dark" fixed="top">
      <Navbar.Brand to="/" as={Link}>SK Delivery {REACT_APP_STAGE}</Navbar.Brand>
      
      { isActive ? (
      <Nav className="container-fluid">
        <Nav.Item><Nav.Link as={Link} to="/" >Home</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link as={Link} to="/plan">Plan</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link as={Link} to="/schedule/0" >Schedule</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link as={Link} to="/admin" >Admin</Nav.Link></Nav.Item>
        
        
        <NavDropdown className="ml-auto" title={activeLocation.name} >
          {grantedList.map((location, i) => 
              <NavDropdown.Item key={location.id} onClick={() => handleLocationChange(location)}>{location.name}</NavDropdown.Item>
          )}
				</NavDropdown>

        <NavDropdown title={usrName} id="basic-nav-dropdown">
          <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
        </NavDropdown>
        
      </Nav>
      ) : ( '' ) }
    </Navbar>
     );
}
 
export default TopNavBar;